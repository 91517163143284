import React, { useState, useMemo } from 'react';
import '../css.components/TasksTab.css';
import durovImg from '../../public/Durov-icon.png';
import sashaImg from '../../public/Sasha-icon.png';
import avitoImg from '../../public/avito-icon.jpg';
import roxmanImg from '../../public/Roxman-icon.png';
import gifButton from '../../public/chanel.gif';
import SOO from '../../public/soon.jpg';

const TasksTab = ({ tasksState = {}, handleTaskClaimFromParent }) => {
  const [buttonState, setButtonState] = useState({});
  const [activeTab, setActiveTab] = useState('social'); // Default tab: 'social'

  const tasksConfig = useMemo(
    () => [
      {
        taskIndex: 1,
        name: 'Durov',
        link: 'https://t.me/durov',
        image: durovImg,
        isClaimed: !!tasksState.task_1,
        reward: tasksState.task_1_reward || 5000,
        rewardType: tasksState.task_1_type || 1, // 1 => EWI
        type: 'social', // For filtering tabs
      },
      {
        taskIndex: 2,
        name: 'Sasha',
        link: 'https://t.me/not_takoy_sasha',
        image: sashaImg,
        isClaimed: !!tasksState.task_2,
        reward: tasksState.task_2_reward || 5000,
        rewardType: tasksState.task_2_type || 1,
        type: 'social',
      },
      {
        taskIndex: 3,
        name: 'Roxman',
        link: 'https://t.me/roxman',
        image: roxmanImg,
        isClaimed: !!tasksState.task_3,
        reward: tasksState.task_3_reward || 5000,
        rewardType: tasksState.task_3_type || 1, // 2 => EWE
        type: 'social',
      },
      {
        taskIndex: 4,
        name: 'Channel',
        link: 'https://t.me/Greenwoods_Community',
        image: gifButton,
        isClaimed: !!tasksState.task_4,
        reward: tasksState.task_4_reward || 5000,
        rewardType: tasksState.task_4_type || 1,
        type: 'social',
      },
//      {
//        taskIndex: 5,
//        name: 'Leave an Avito comment',
//        link: 'https://t.me/avitoGreenwoods',
//        image: avitoImg,
//        isClaimed: false,
//        reward: "Start",
//        rewardType: 3,
//        type: 'team',
//      },
    ],
    [tasksState]
  );

  const openLinkWithoutClosing = (url) => {
    if (
      window.Telegram?.WebApp &&
      typeof window.Telegram.WebApp.openTelegramLink === 'function'
    ) {
      window.Telegram.WebApp.openTelegramLink(url);
    } else {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  const handleTaskImageClick = (task) => {
    openLinkWithoutClosing(task.link);
  };

  const handleClaimClick = (task) => {
    setButtonState((prev) => ({ ...prev, [task.taskIndex]: 'onclic' }));

    // Open the link (if you want them to join or visit first)
    openLinkWithoutClosing(task.link);

    // Pass taskIndex, reward, and rewardType to parent so EWI/EWE can be updated
    handleTaskClaimFromParent(task.taskIndex, task.reward, task.rewardType);

    // Simulate a 'validation' style button effect
    setTimeout(() => {
      setButtonState((prev) => ({ ...prev, [task.taskIndex]: 'validate' }));
      setTimeout(() => {
        setButtonState((prev) => ({ ...prev, [task.taskIndex]: '' }));
      }, 1250);
    }, 2250);
  };

  // Filter tasks by activeTab, then sort so claimed tasks go last
  const sortedTasks = tasksConfig
    .filter((task) => task.type === activeTab)
    .sort((a, b) => {
      return a.isClaimed === b.isClaimed ? 0 : a.isClaimed ? 1 : -1;
    });

  return (
    <div className="tasks">
      {/* Tab switcher */}
      <div className="task-tabs">
        <button
          className={`tab-button ${activeTab === 'social' ? 'active' : ''}`}
          onClick={() => setActiveTab('social')}
        >
          SOCIAL
        </button>
        <button
          className={`tab-button ${activeTab === 'team' ? 'active' : ''}`}
          onClick={() => setActiveTab('team')}
        >
          TEAM
        </button>
      </div>

      <div className="tasks-list">
        {sortedTasks.map((task) => (
          <div
            key={task.taskIndex}
            className={`task-card ${task.isClaimed ? 'completed' : ''}`}
          >
            <img
              className="task-img"
              src={task.image}
              alt={task.name}
              width="80"
              height="80"
              onClick={() => handleTaskImageClick(task)}
            />

            <strong>{task.name}</strong>

            {task.isClaimed ? (
              <button className="claimed-button" disabled>
                Claimed
              </button>
            ) : (
              <button
                className={buttonState[task.taskIndex]}
                onClick={() => handleClaimClick(task)}
              >
                <span style={{ marginLeft: '0px' }}>
                  GET {task.reward}{' '}
                  {task.rewardType === 1
                    ? 'EWI'
                    : task.rewardType === 2
                    ? 'EWE'
										: task.rewardType === 3
                    ? ''
                    : '??'}
                </span>
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TasksTab;
