import React, { useState, useEffect, useRef } from 'react';
import UniverseData from '../UniverseData';
import '../css.components/UpgradeTab.css';

import BOT from '../../public/bot.gif';
import BOOST1 from '../../public/refresh.gif';
import BOOST2 from '../../public/boosttap.gif';
import DAMAGE_UPGRADE from '../../public/click.gif';
import ENERGY_UPGRADE from '../../public/Energy.gif';
import REGEN_UPGRADE from '../../public/regen.gif';

/* ============== Upgrade cost helpers ============== */
function getDamageCost(damageLevel) {
  return Math.round(250 * Math.pow(damageLevel + 1, 5));
}

function getEnergyCost(energyLevel) {
  return Math.round(200 * Math.pow(energyLevel + 1, 5));
}

function getRegenCost(regenLevel) {
  return Math.round(300 * Math.pow(regenLevel + 1, 5));
}

/* ============== Midnight countdown helpers ============== */
function getMsUntilNextMidnightUTC() {
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = now.getUTCMonth();
  const day = now.getUTCDate();
  const nextMidnight = new Date(Date.UTC(year, month, day + 1, 0, 0, 0));
  return nextMidnight.getTime() - now.getTime();
}

function formatHMS(ms) {
  if (ms <= 0) return '00:00:00';
  const totalSeconds = Math.floor(ms / 1000);
  const hrs = Math.floor(totalSeconds / 3600);
  const mins = Math.floor((totalSeconds % 3600) / 60);
  const secs = totalSeconds % 60;
  return `${String(hrs).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
}

// Helper function to format boost names to human-friendly strings
function formatBoostName(boostType) {
  switch (boostType) {
    case 'maxEnergy':
      return 'max energy';
    case 'tapMultiplier':
      return 'tap multiplier';
    default:
      return boostType;
  }
}

// A simple confirmation dialog component
const ConfirmDialog = ({ message, onConfirm, onCancel }) => (
  <div className="confirm-dialog-overlay">
    <div className="confirm-dialog">
      <p>{message}</p>
      <div className="confirm-buttons">
        <button onClick={onConfirm}>Confirm</button>
        <button onClick={onCancel}>Cancel</button>
      </div>
    </div>
  </div>
);

/**
 * Props:
 *   - damageLevel, energyLevel, regenLevel, currentEWI
 *   - handleUpgrade, handleBoost, dailyBoostMe, dailyBoostMt, onCloseUpgrades
 *   - tonLeft (float)       => user.ton_left
 *   - tonIsFarming (bool)   => user.ton_isfarming
 *   - tonEwiFarmed (float)  => user.ton_ewi_farmed
 *   - onAutoFarmComplete()  => callback so parent re-fetches UniverseData
 */
const UpgradeTab = ({
  damageLevel,
  energyLevel,
  regenLevel,
  currentEWI,
  handleUpgrade,
  handleBoost,
  dailyBoostMe,
  dailyBoostMt,
  onCloseUpgrades,
  tonLeft,
  tonIsFarming,
  tonEwiFarmed,
  onAutoFarmComplete
}) => {
  // ========== UI feedback for upgrade/boost buttons ==========
  const [buttonState, setButtonState] = useState({ damage: '', energy: '', regen: '' });
  const [boostButtonState, setBoostButtonState] = useState({ maxEnergy: '', tapMultiplier: '' });

  // ========== Daily usage tracking for local UI ==========
  const [localDailyBoostMe, setLocalDailyBoostMe] = useState(dailyBoostMe);
  const [localDailyBoostMt, setLocalDailyBoostMt] = useState(dailyBoostMt);

  // ========== Midnight countdown ==========
  const [timeToMidnight, setTimeToMidnight] = useState(getMsUntilNextMidnightUTC());
  useEffect(() => {
    const intv = setInterval(() => {
      setTimeToMidnight(getMsUntilNextMidnightUTC());
    }, 1000);
    return () => clearInterval(intv);
  }, []);
  const countdownLabel = formatHMS(timeToMidnight);

  // ========== Upgrade cost calculations ==========
  const damageCost = getDamageCost(damageLevel);
  const energyCost = getEnergyCost(energyLevel);
  const regenCost = getRegenCost(regenLevel);

  // Check daily usage
  const hasUsedMaxEnergyToday = localDailyBoostMe >= 1;
  const hasUsedTapMultiplierToday = localDailyBoostMt >= 1;

  // ========== Confirmation Dialog state ==========
  const [confirmData, setConfirmData] = useState({ show: false, message: '', callback: null });

  const openConfirm = (message, callback) => {
    setConfirmData({ show: true, message, callback });
  };

  const handleConfirm = () => {
    if (confirmData.callback) confirmData.callback();
    setConfirmData({ show: false, message: '', callback: null });
  };

  const handleCancel = () => {
    setConfirmData({ show: false, message: '', callback: null });
  };

  // ====== Original upgrade action ======
  const handleUpgradeClick = (upgradeType, cost) => {
    setButtonState((prev) => ({ ...prev, [upgradeType]: 'onclic' }));
    handleUpgrade(upgradeType, cost);
    UniverseData.syncActions(null, false);

    setTimeout(() => {
      setButtonState((prev) => ({ ...prev, [upgradeType]: 'validate' }));
      setTimeout(() => {
        setButtonState((prev) => ({ ...prev, [upgradeType]: '' }));
      }, 1250);
    }, 2250);
  };

  // ====== Wrap upgrade action with confirmation ==========
  const handleUpgradeClickWithConfirm = (upgradeType, cost) => {
    openConfirm(`Are you sure you want to upgrade ${upgradeType} for ${cost} EWI?`, () =>
      handleUpgradeClick(upgradeType, cost)
    );
  };

  // ====== Original boost action ==========
  const handleBoostClickLocal = (boostType) => {
    setBoostButtonState((prev) => ({ ...prev, [boostType]: 'onclic' }));
    handleBoost(boostType);
    UniverseData.syncActions(null, false);

    if (boostType === 'maxEnergy') {
      setLocalDailyBoostMe(1);
    }
    if (boostType === 'tapMultiplier') {
      setLocalDailyBoostMt(1);
    }
    if (onCloseUpgrades) onCloseUpgrades();

    setTimeout(() => {
      setBoostButtonState((prev) => ({ ...prev, [boostType]: 'validate' }));
      setTimeout(() => {
        setBoostButtonState((prev) => ({ ...prev, [boostType]: '' }));
      }, 1250);
    }, 2250);
  };

  // ====== Wrap boost action with confirmation ==========
  const handleBoostClickWithConfirm = (boostType) => {
    const boostName = formatBoostName(boostType);
    openConfirm(`Are you sure you want to use boost ${boostName}?`, () =>
      handleBoostClickLocal(boostType)
    );
  };

  // ====== AUTO-FARMING LOGIC ==========
  const [autoFarming, setAutoFarming] = useState(false);
  const [localTonLeft, setLocalTonLeft] = useState(tonLeft || 0);
  const [localTonFarmedEwi, setLocalTonFarmedEwi] = useState(tonEwiFarmed || 0);

  const ONE_WEEK_MS = 7 * 24 * 60 * 60 * 1000;
  const FARM_DURATION_PER_TON_MS = ONE_WEEK_MS;
  const EWI_PER_TON = 700000;

  const intervalRef = useRef(null);
  const startRef = useRef(0);
  const initialTonRef = useRef(0);

  useEffect(() => {
    if (tonIsFarming) {
      startAutoFarming(true, tonLeft, tonEwiFarmed);
    }
    fetchAutoFarmData();
    return () => cleanupInterval();
  }, []);

  async function fetchAutoFarmData() {
    try {
      const sessionId = UniverseData.sessionId;
      if (!sessionId) return;
      const url = `${process.env.REACT_APP_BACKEND_URL}/autofarm?session_id=${sessionId}`;
      const res = await fetch(url);
      if (!res.ok) {
        console.error('[UpgradeTab] /autofarm =>', res.status);
        return;
      }
      const data = await res.json();
      if (data.ton_isfarming) {
        reSyncLocalPartial(data.ton_left, data.ton_ewi_farmed);
      } else {
        cleanupInterval();
        setAutoFarming(false);
        setLocalTonLeft(data.ton_left || 0);
        setLocalTonFarmedEwi(0);
        const userData = UniverseData.getCurrentData()?.user;
        if (userData) {
          userData.ton_left = data.ton_left || 0;
          userData.ton_ewi_farmed = 0;
          userData.ton_isfarming = false;
        }
      }
    } catch (err) {
      console.error('[UpgradeTab] fetchAutoFarmData =>', err);
    }
  }

  function reSyncLocalPartial(newLeft, newFarmed) {
    if (!autoFarming) {
      startAutoFarming(true, newLeft, newFarmed);
    } else {
      if (initialTonRef.current > 0) {
        const fraction = newFarmed / (initialTonRef.current * EWI_PER_TON);
        if (fraction >= 1) {
          finalizeAutoFarming();
          return;
        }
        const usedMs = fraction * (initialTonRef.current * FARM_DURATION_PER_TON_MS);
        startRef.current = Date.now() - usedMs;
      }
      setLocalTonLeft(newLeft);
      setLocalTonFarmedEwi(newFarmed);
      const userData = UniverseData.getCurrentData()?.user;
      if (userData) {
        userData.ton_left = newLeft;
        userData.ton_ewi_farmed = newFarmed;
        userData.ton_isfarming = true;
      }
    }
  }

  useEffect(() => {
    if (tonIsFarming) {
      startAutoFarming(true, tonLeft, tonEwiFarmed);
    } else {
      cleanupInterval();
      setAutoFarming(false);
      setLocalTonLeft(tonLeft || 0);
      setLocalTonFarmedEwi(tonEwiFarmed || 0);
    }
  }, [tonIsFarming]);

  useEffect(() => {
    if (!tonIsFarming) {
      setLocalTonLeft(tonLeft || 0);
      setLocalTonFarmedEwi(tonEwiFarmed || 0);
    }
  }, [tonLeft, tonEwiFarmed, tonIsFarming]);

  function startAutoFarming(reinit, forcedLeft, forcedFarmed) {
    const activeLeft = typeof forcedLeft === 'number' ? forcedLeft : localTonLeft;
    if (activeLeft <= 0) return;
    setAutoFarming(true);

    if (!reinit) {
      UniverseData.handleTonFarming(true);
    }

    initialTonRef.current = activeLeft;
    startRef.current = Date.now();

    if (reinit && typeof forcedFarmed === 'number') {
      let fraction = forcedFarmed / (activeLeft * EWI_PER_TON);
      if (fraction < 0) fraction = 0;
      if (fraction > 1) fraction = 1;
      const totalTimeMs = activeLeft * FARM_DURATION_PER_TON_MS;
      const usedMs = fraction * totalTimeMs;
      startRef.current = Date.now() - usedMs;
    }

    cleanupInterval();
    intervalRef.current = setInterval(() => {
      const now = Date.now();
      const elapsed = now - startRef.current;
      const totalTimeMs = initialTonRef.current * FARM_DURATION_PER_TON_MS;
      let fraction = elapsed / totalTimeMs;
      if (fraction > 1) fraction = 1;
      const partialEwi = initialTonRef.current * EWI_PER_TON * fraction;
      setLocalTonFarmedEwi(partialEwi);
      const consumed = initialTonRef.current * fraction;
      setLocalTonLeft(initialTonRef.current - consumed);
      const userData = UniverseData.getCurrentData()?.user;
      if (userData) {
        userData.ton_isfarming = true;
        userData.ton_left = initialTonRef.current - consumed;
        userData.ton_ewi_farmed = partialEwi;
      }
      if (fraction >= 1) {
        finalizeAutoFarming();
      }
    }, 1000);
  }

  async function finalizeAutoFarming() {
    cleanupInterval();
    setAutoFarming(false);
    const data = UniverseData.getCurrentData();
    if (data && data.ewi) {
      data.ewi.currentEWI += localTonFarmedEwi;
      data.ewi.totalEWI += localTonFarmedEwi;
    }
    if (data && data.user) {
      data.user.ton_left = localTonLeft;
      data.user.ton_ewi_farmed = 0;
      data.user.ton_isfarming = false;
    }
    UniverseData.handleTonFarming(false);
    try {
      await UniverseData.syncActions(null, false);
      await UniverseData.refreshGameState();
    } catch (err) {
      console.error('[UpgradeTab] finalizeAutoFarming =>', err);
    }
    if (onAutoFarmComplete) {
      onAutoFarmComplete();
    }
  }

  function stopAutoFarming() {
    finalizeAutoFarming();
  }

  function cleanupInterval() {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }

  // ====== Wrap auto-farm actions with confirmation ==========
  const handleAutoFarmStartWithConfirm = () => {
    openConfirm("Are you sure you want to start auto-farming?", () =>
      startAutoFarming(false)
    );
  };

  const handleAutoFarmStopWithConfirm = () => {
    openConfirm("Are you sure you want to stop auto-farming and collect your rewards?", () =>
      stopAutoFarming()
    );
  };

  function renderAutoFarmButton() {
    if (autoFarming) {
      return (
        <button className="auto-farm-btn active" onClick={handleAutoFarmStopWithConfirm}>
          <div className="bot-icon">
            <img src={BOT} alt="Bot" width="50" height="50" />
          </div>
          <p>Farming... EWI Farmed: {localTonFarmedEwi.toFixed(2)}</p>
          <p>TON Remaining: {localTonLeft.toFixed(3)}</p>
          <span className="tooltip">Tap to stop and collect</span>
        </button>
      );
    }
    return (
      <button
        className="auto-farm-btn inactive"
        onClick={handleAutoFarmStartWithConfirm}
        disabled={localTonLeft <= 0}
      >
        <div className="bot-icon">
          <img src={BOT} alt="Bot" width="50" height="50" />
        </div>
        <p>Auto-Farming Off</p>
        <p>Available TON: {localTonLeft.toFixed(3)}</p>
        <span className="tooltip">Tap to start farming</span>
      </button>
    );
  }

  return (
    <div className="upgrades">
      {confirmData.show && (
        <ConfirmDialog
          message={confirmData.message}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
      <div className="upgrade-section">
        <h4 className="header_upg">Upgrades</h4>
        <button
          className={buttonState.damage}
          onClick={() => handleUpgradeClickWithConfirm('damage', damageCost)}
          disabled={currentEWI < damageCost}
        >
          <div className="upgrade-icon">
            <img src={DAMAGE_UPGRADE} alt="Damage Upgrade" width="50" height="50" />
          </div>
          <h4 className="upgrade-txt">Damage {damageLevel}</h4>
          <p>+1 damage per click</p>
          <p>Cost: {damageCost} EWI</p>
        </button>
        <button
          className={buttonState.energy}
          onClick={() => handleUpgradeClickWithConfirm('energy', energyCost)}
          disabled={currentEWI < energyCost}
        >
          <div className="upgrade-icon">
            <img src={ENERGY_UPGRADE} alt="Energy Upgrade" width="50" height="50" />
          </div>
          <h4 className="upgrade-txt">Energy {energyLevel}</h4>
          <p>+500 max energy</p>
          <p>Cost: {energyCost} EWI</p>
        </button>
        {regenLevel < 5 ? (
          <button
            className={buttonState.regen}
            onClick={() => handleUpgradeClickWithConfirm('regen', regenCost)}
            disabled={currentEWI < regenCost}
          >
            <div className="upgrade-icon">
              <img src={REGEN_UPGRADE} alt="Regen Upgrade" width="50" height="50" />
            </div>
            <h4 className="upgrade-txt">Regen {regenLevel}</h4>
            <p>+1 regeneration speed</p>
            <p>Cost: {regenCost} EWI</p>
          </button>
        ) : (
          <div className="upgrade-placeholder">
            <h4 className="upgrade-txt">Max Regeneration Level Reached</h4>
          </div>
        )}
      </div>

      <div className="autofarm-section">
        <h4 className="header_upg">Auto-Farming Bot</h4>
        {renderAutoFarmButton()}
      </div>

      <div className="boost-section">
        <h4 className="header_upg">Boosts</h4>
        <div className="boost-row">
          <button
            className={boostButtonState.maxEnergy}
            onClick={() => handleBoostClickWithConfirm('maxEnergy')}
            disabled={hasUsedMaxEnergyToday}
          >
            <div className="boost-icon">
              <img src={BOOST1} alt="Boost 1 Animation" width="50" height="50" />
            </div>
            <h4>MAX Energy</h4>
            <p>Fully restores your energy.</p>
            {hasUsedMaxEnergyToday ? (
              <span className="tooltip">Used! Resets in {countdownLabel}</span>
            ) : (
              <span className="tooltip">1 free daily usage</span>
            )}
          </button>
          <button className="disabled-btn" disabled>
            <div className="boost-icon">
              <img src={BOOST1} alt="Ad" width="25" height="25" />
            </div>
            <p>Watch Ad (coming soon)</p>
          </button>
        </div>

        <div className="boost-row">
          <button
            className={boostButtonState.tapMultiplier}
            onClick={() => handleBoostClickWithConfirm('tapMultiplier')}
            disabled={hasUsedTapMultiplierToday}
          >
            <div className="boost-icon">
              <img src={BOOST2} alt="Boost 2 Animation" width="50" height="50" />
            </div>
            <h4>Progressive TAP</h4>
            <p>Multiplies your taps by 3 for 10 seconds</p>
            {hasUsedTapMultiplierToday ? (
              <span className="tooltip">Used! Resets in {countdownLabel}</span>
            ) : (
              <span className="tooltip">1 free daily usage</span>
            )}
          </button>
          <button className="disabled-btn" disabled>
            <div className="boost-icon">
              <img src={BOOST2} alt="Ad2" width="25" height="25" />
            </div>
            <p>Watch Ad (coming soon)</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpgradeTab;
