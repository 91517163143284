import React, { useState, useRef, useEffect } from 'react';
import '../css.components/tutorial.css';  // your CSS

const Tutorial = ({ onClose }) => {
  const [step, setStep] = useState(0);
  const [lang, setLang] = useState('en');
  const [boxPosition, setBoxPosition] = useState({ top: 0, left: 0, width: 0, height: 0 });

  // Detect if user is on Android
  const isAndroid = /Android/i.test(navigator.userAgent);

  // Refs to your UI elements
  const energyRef = useRef(null);
  const walletRef = useRef(null);
  const exchangeRef = useRef(null);
  const giftboxRef = useRef(null);
  const miniGamesRef = useRef(null);
  const referralRef = useRef(null);
  const leaderboardRef = useRef(null);

  // Map string -> actual React ref
  const refMap = {
    energyRef,
    walletRef,
    exchangeRef,
    giftboxRef,
    miniGamesRef,
    referralRef,
    leaderboardRef,
  };

  // Titles that need 30px top offset on Android (EN + RU)
  const androidOffsetTitlesEn = [
    'Referral Zone',
    'Wallet',
    'Mini-Games',
    'Exchange',
    'Upgrades',
  ];
  const androidOffsetTitlesRu = [
    'Реферальная зона',
    'Кошелек',
    'Мини-игры',
    'Биржа',
    'Улучшения',
  ];

  // EN steps
  const stepsEn = [
    {
      title: 'Welcome to the game!',
      description:
        'Tap the screen to build and improve your universe. Let’s start with the basics.',
      targetRef: null,
      position: { top: 10, left: 30, width: 20, height: 10 },
    },
    {
      title: 'Referral Zone',
      description:
        'Invite friends and receive bonuses. Your friends also get a bonus.',
      targetRef: 'referralRef',
      position: { top: 93, left: 3, width: 15, height: 6 },
    },
    {
      title: 'Wallet',
      description:
        'Connect your wallet and donate to receive special rewards and bonuses.',
      targetRef: 'walletRef',
      position: { top: 93, left: 22, width: 15, height: 6 },
    },
    {
      title: 'Mini-Games',
      description:
        'Play mini-games to earn extra EWI while you have no energy left.',
      targetRef: 'miniGamesRef',
      position: { top: 93, left: 42, width: 15, height: 6 },
    },
    {
      title: 'Exchange',
      description:
        'Exchange your currency for other useful currencies.',
      targetRef: 'exchangeRef',
      position: { top: 93, left: 62, width: 15, height: 6 },
    },
    {
      title: 'Upgrades',
      description:
        'Upgrade your account to gain passive bonuses. Don’t forget daily rewards too.',
      targetRef: 'exchangeRef',
      position: { top: 93, left: 82, width: 15, height: 6 },
    },
    {
      title: 'Daily',
      description:
        'Log in daily to get bonuses. The streak increases your rewards.',
      targetRef: 'giftboxRef',
      position: { top: 27, left: 83, width: 15, height: 7 },
    },
    {
      title: 'Giftbox',
      description:
        'Spin the wheel to receive a random daily bonus based on luck.',
      targetRef: 'giftboxRef',
      position: { top: 18.5, left: 83, width: 15, height: 7 },
    },
    {
      title: 'Tasks',
      description:
        'Complete tasks to earn a one-time bonus.',
      targetRef: 'energyRef',
      position: { top: 10, left: 83, width: 15, height: 7 },
    },
    {
      title: 'Notifications',
      description:
        'Get notifications about important events and occasional bonuses.',
      targetRef: 'energyRef',
      position: { top: 18.5, left: 2.5, width: 15, height: 7 },
    },
    {
      title: 'Leaderboard & Profile',
      description:
        'See the top 20 donors and access your profile.',
      targetRef: 'leaderboardRef',
      position: { top: 10, left: 2.5, width: 15, height: 7 },
    },
    {
      title: 'Upgrade Energy',
      description:
        'Use energy to tap on the coin and power up your universe.',
      targetRef: 'energyRef',
      position: { top: 7, left: 41.5, width: 15, height: 2.5 },
    },
    {
      title: 'Total Coin Amount',
      description:
        'Keep an eye on your EWI balance, used for upgrades and growth.',
      targetRef: 'energyRef',
      position: { top: 12.5, left: 36, width: 30, height: 5 },
    },
    {
      title: 'EWE Dimension',
      description:
        'Swipe left for EWE. It passively collects currency while you focus elsewhere.',
      targetRef: null,
      position: { top: 60, left: 30, width: 20, height: 10 },
    },
    {
      title: 'ECI Dimension',
      description:
        'Swipe right for ECI, an eco-friendly dimension currently under development.',
      targetRef: null,
      position: { top: 70, left: 30, width: 20, height: 10 },
    },
  ];

  // RU steps
  const stepsRu = [
    {
      title: 'Добро пожаловать в игру!',
      description:
        'Нажимайте на экран, чтобы развивать свою вселенную. Начнём с основ.',
      targetRef: null,
      position: { top: 10, left: 30, width: 20, height: 10 },
    },
    {
      title: 'Реферальная зона',
      description:
        'Приглашайте друзей и получайте бонусы. Друзья тоже получают награду.',
      targetRef: 'referralRef',
      position: { top: 93, left: 3, width: 15, height: 6 },
    },
    {
      title: 'Кошелек',
      description:
        'Подключите кошелек и внесите донат, чтобы получить особые бонусы.',
      targetRef: 'walletRef',
      position: { top: 93, left: 22, width: 15, height: 6 },
    },
    {
      title: 'Мини-игры',
      description:
        'Играйте в мини-игры, чтобы заработать дополнительную EWI без затрат энергии.',
      targetRef: 'miniGamesRef',
      position: { top: 93, left: 42, width: 15, height: 6 },
    },
    {
      title: 'Биржа',
      description:
        'Обменивайте внутриигровую валюту на полезные валюту.',
      targetRef: 'exchangeRef',
      position: { top: 93, left: 62, width: 15, height: 6 },
    },
    {
      title: 'Улучшения',
      description:
        'Прокачайте аккаунт, чтобы получать пассивные бонусы. Не забудьте про ежедневные награды.',
      targetRef: 'exchangeRef',
      position: { top: 93, left: 82, width: 15, height: 6 },
    },
    {
      title: 'Ежедневки',
      description:
        'Заходите в игру каждый день, чтобы получать растущие бонусы.',
      targetRef: 'giftboxRef',
      position: { top: 27, left: 83, width: 15, height: 7 },
    },
    {
      title: 'Гифтбокс',
      description:
        'Крутите колесо и получайте случайный дневной бонус.',
      targetRef: 'giftboxRef',
      position: { top: 18.5, left: 83, width: 15, height: 7 },
    },
    {
      title: 'Таски',
      description:
        'Выполняйте задания, чтобы получить одноразовый бонус ресурсами или предметами.',
      targetRef: 'energyRef',
      position: { top: 10, left: 83, width: 15, height: 7 },
    },
    {
      title: 'Уведомления',
      description:
        'Получайте уведомления о важных событиях и дополнительных бонусах.',
      targetRef: 'energyRef',
      position: { top: 18.5, left: 2.5, width: 15, height: 7 },
    },
    {
      title: 'Лидерборд & Профиль',
      description:
        'Смотрите топ-20 донатеров и управляйте своим профилем.',
      targetRef: 'leaderboardRef',
      position: { top: 10, left: 2.5, width: 15, height: 7 },
    },
    {
      title: 'Энергия улучшения',
      description:
        'Используйте энергию, чтобы нажимать на монету и улучшать вселенную.',
      targetRef: 'energyRef',
      position: { top: 7, left: 41.5, width: 15, height: 2.5 },
    },
    {
      title: 'Общее количество монет',
      description:
        'Следите за количеством EWI, которые нужны для развития и улучшений.',
      targetRef: 'energyRef',
      position: { top: 12.5, left: 36, width: 30, height: 5 },
    },
    {
      title: 'Измерение EWE',
      description:
        'Сделайте свайп влево для EWE. Оно пассивно добывает валюту, пока вы заняты другим.',
      targetRef: null,
      position: { top: 60, left: 30, width: 20, height: 10 },
    },
    {
      title: 'Измерение ECI',
      description:
        'Свайп вправо, чтобы попасть в ECI — экологичное измерение в разработке.',
      targetRef: null,
      position: { top: 70, left: 30, width: 20, height: 10 },
    },
  ];

  const buttonTexts = {
    en: {
      next: 'Next',
      close: "Got it, let's continue!",
    },
    ru: {
      next: 'Далее',
      close: 'Понял, продолжим!',
    },
  };

  // Choose steps based on language
  const steps = lang === 'en' ? stepsEn : stepsRu;

  // Move highlight box
  const updateBoxPosition = (elementRef, position, stepObj) => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    // Convert percentage to px
    let boxTop = (position.top / 100) * screenHeight;
    let boxLeft = (position.left / 100) * screenWidth;
    let boxWidth = (position.width / 100) * screenWidth;
    let boxHeight = (position.height / 100) * screenHeight;

    // Check if the title is in the list that needs offset on Android
    if (isAndroid) {
      const stepTitle = stepObj.title;
      const needsEnOffset = androidOffsetTitlesEn.includes(stepTitle);
      const needsRuOffset = androidOffsetTitlesRu.includes(stepTitle);
      if (needsEnOffset || needsRuOffset) {
        boxTop -= 65; // shift 60px upwards
      }
    }

    // Constrain to screen
    const constrainedTop = Math.max(0, Math.min(boxTop, screenHeight - boxHeight));
    const constrainedLeft = Math.max(0, Math.min(boxLeft, screenWidth - boxWidth));
    const constrainedWidth = Math.min(boxWidth, screenWidth - constrainedLeft);
    const constrainedHeight = Math.min(boxHeight, screenHeight - constrainedTop);

    setBoxPosition({
      top: constrainedTop,
      left: constrainedLeft,
      width: constrainedWidth,
      height: constrainedHeight,
    });
  };

  useEffect(() => {
    const stepObj = steps[step];
    const { targetRef, position } = stepObj;
    const realRef = targetRef ? refMap[targetRef] : null;
    if (position) {
      updateBoxPosition(realRef, position, stepObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, steps]);

  // Navigation
  const handleNextStep = () => {
    if (step < steps.length - 1) setStep(step + 1);
  };
  const handleClose = () => {
    onClose();
  };

  // Show swipe arrows on last two steps
  const showSwipeArrows = step >= steps.length - 2;

  return (
    <>
      {/* TUTORIAL OVERLAY & CONTAINER */}
      <div className="tutorial-overlay">
        <div className="tutorial-container">
          {/* Language Dropdown */}
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="langSelect" style={{ marginRight: '5px' }}>
              Language:
            </label>
            <select
              id="langSelect"
              value={lang}
              onChange={(e) => setLang(e.target.value)}
            >
              <option value="en">EN</option>
              <option value="ru">RU</option>
            </select>
          </div>

          {/* Step Content */}
          <h2>{steps[step].title}</h2>
          <p>{steps[step].description}</p>

          {/* Next / Close Button */}
          {step < steps.length - 1 ? (
            <button onClick={handleNextStep}>
              {buttonTexts[lang].next}
            </button>
          ) : (
            <button onClick={handleClose}>
              {buttonTexts[lang].close}
            </button>
          )}
        </div>

        {/* Highlight Box (if there's a targetRef) */}
        {steps[step].targetRef && (
          <div
            className="tutorial-box"
            style={{
              top: boxPosition.top + 'px',
              left: boxPosition.left + 'px',
              width: boxPosition.width + 'px',
              height: boxPosition.height + 'px',
              transition: 'all 0.5s ease',
              pointerEvents: 'none',
            }}
          />
        )}
      </div>

      {/* SWIPE ARROWS UNDER THE TUTORIAL BOX */}
      {showSwipeArrows && (
        <div className="swipe-arrows-container">
          <div className="swipe-arrow-left" />
          <div className="swipe-arrow-right" />
        </div>
      )}
    </>
  );
};

export default Tutorial;
