// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import UniverseSwitcher from './components/UniverseSwitcher';
import { TonConnectUIProvider } from '@tonconnect/ui-react';

const MANIFEST_URL = 'https://valerysm.com/tonconnect-manifest.json';

const allowedWallets = [
  { id: "tonkeeper", name: "TonKeeper" },
  { id: "tonwallet", name: "Ton Wallet" },
  { id: "telegram", name: "Telegram Wallet" },
  { id: "tonhub", name: "Ton Hub" }
];

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <TonConnectUIProvider manifestUrl={MANIFEST_URL} wallets={allowedWallets}>
      <UniverseSwitcher />
    </TonConnectUIProvider>
  </React.StrictMode>
);
