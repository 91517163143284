import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import EWE from '../Universes/EWE/src/EWE';
import EcoGame from '../Universes/ECI/EcoGame';
import EatsApp from '../Universes/EWI/src/EatsApp';
import '../css/UniverseSwitcher.css';

import loadingVideo from '../components/public/load_screen_univ1.mp4';

const UniverseSwitcher = () => {
  const [currentUniverse, setCurrentUniverse] = useState('EWI');
  const [nextUniverse, setNextUniverse] = useState('');
  const [isTabOpen, setIsTabOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Hide flicker until video is loaded
  const [videoLoaded, setVideoLoaded] = useState(false);

  const universeOrder = ['EWE', 'ECI', 'EWI'];

  const SWIPE_THRESHOLD = 200;
  const SWIPE_VELOCITY = 0.3;

  const getNextUniverse = (direction) => {
    const currentIndex = universeOrder.indexOf(currentUniverse);
    if (direction === 'next') {
      return universeOrder[(currentIndex + 1) % universeOrder.length];
    } else {
      return universeOrder[(currentIndex - 1 + universeOrder.length) % universeOrder.length];
    }
  };

  const changeUniverse = (direction) => {
    setIsLoading(true);
    const next = getNextUniverse(direction);
    setNextUniverse(next);

    // e.g. 2s or 3s "loading" transition for your global screen
    setTimeout(() => {
      setCurrentUniverse(next);
      setIsLoading(false);
      setVideoLoaded(false);
    }, 3000);
  };

  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      if (eventData.dir === 'Up' || eventData.dir === 'Down') {
        eventData.event.preventDefault();
      }
    },
    onSwipedLeft: ({ deltaX, velocity }) => {
      if (
        Math.abs(deltaX) >= SWIPE_THRESHOLD &&
        velocity >= SWIPE_VELOCITY &&
        !isTabOpen &&
        !isLoading
      ) {
        changeUniverse('next');
      }
    },
    onSwipedRight: ({ deltaX, velocity }) => {
      if (
        Math.abs(deltaX) >= SWIPE_THRESHOLD &&
        velocity >= SWIPE_VELOCITY &&
        !isTabOpen &&
        !isLoading
      ) {
        changeUniverse('prev');
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackTouch: true
  });

  const renderUniverse = () => {
    switch (currentUniverse) {
      case 'EWE':
        return <EWE setIsTabOpen={setIsTabOpen} fromUniverseSwitcherLoading={isLoading} />;
      case 'ECI':
        return <EcoGame setIsTabOpen={setIsTabOpen} fromUniverseSwitcherLoading={isLoading} />;
      case 'EWI':
        return <EatsApp setIsTabOpen={setIsTabOpen} fromUniverseSwitcherLoading={isLoading} />;
      default:
        return <EWE setIsTabOpen={setIsTabOpen} fromUniverseSwitcherLoading={isLoading} />;
    }
  };

  return (
    <div className="container" {...handlers}>
      <main>
        {isLoading ? (
          <div className="loading-screen">
            {/* Fallback until video is loaded */}
            {!videoLoaded && <div className="video-fallback" />}

            <video
              className="loading-video"
              src={loadingVideo}
              autoPlay
              loop
              muted
              playsInline
              // Some iOS devices need lowercase playsinline too:
              playsinline="true"
              webkit-playsinline="true"
              disablePictureInPicture
              controlsList="nodownload noremoteplayback"
              onLoadedData={() => setVideoLoaded(true)}
              style={{ 
                display: videoLoaded ? 'block' : 'none',
                pointerEvents: 'none' // <--- important: can't be paused by tap
              }}
            />

            <p>{nextUniverse ? `Changing To ${nextUniverse}` : 'Loading...'}</p>
          </div>
        ) : (
          renderUniverse()
        )}
      </main>
    </div>
  );
};

export default UniverseSwitcher;
