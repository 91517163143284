// Ewe.js
import React, { useState, useEffect, useRef } from 'react';
import UniverseData from '../../EWI/src/UniverseData';
import Score from './comp/Score';
import FarmButton from './comp/FarmButton';
import CircularProgressBar from './comp/CircularProgressBar';
import Background from './comp/Background';
import './css/EWE.css';

// =================== ADDED THIS IMPORT (for local loading) =====================
import loadingVideo from './Loading.mp4';
// ==============================================================================

const DEBUG = true;

function parseAsUTC(str) {
  if (typeof str !== 'string') return null;
  if (!/Z$/.test(str) && !/[+-]\d\d:\d\d$/.test(str)) {
    return new Date(str + 'Z').getTime();
  }
  return new Date(str).getTime();
}

function Ewe({ setIsTabOpen, fromUniverseSwitcherLoading = false }) {
  const log = (msg, data) => DEBUG && console.log(`[EWE Debug] ${msg}`, data);

  const [gameState, setGameState] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchedRef = useRef(false);

  // Local EWE states
  const [tokens, setTokens] = useState(0);
  const [farmed, setFarmed] = useState(0);
  const [isFarming, setIsFarming] = useState(false);
  const [startTime, setStartTime] = useState(null);

  // Constants
  const maxTokens = 0.128;
  const farmDuration = 12 * 3600; // 12 hours in seconds

  const syncEweData = (newData) => {
    UniverseData.updateEweData(newData);
    UniverseData.syncActions();
  };

  useEffect(() => {
    if (fetchedRef.current) return;
    fetchedRef.current = true;

    // 1) Attempt to get from UniverseData
    let sessionId = UniverseData.getCurrentData()?.session_id;
    // We also try the alternative field:
    if (!sessionId) {
      sessionId = UniverseData.getCurrentData()?.sessionId; // ADDED fallback
    }

    // 2) If still not found, try from URL
    if (!sessionId) {
      const urlParams = new URLSearchParams(window.location.search);
      sessionId = urlParams.get('session_id');
    }

    // 3) If still missing, read from localStorage
    if (!sessionId) {
      sessionId = localStorage.getItem('session_id');
    }

    if (!sessionId) {
      console.warn('[EWE] No session_id => skipping fetch');
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/game_state?session_id=${sessionId}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Error fetching game_state: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        // ---- ADDED THIS so UniverseData has the session ID ----
        data.session_id = sessionId;
        data.sessionId = sessionId;

        UniverseData.initialize(data);
        setGameState(data);

        const eweData = data.ewe || {};
        setTokens(eweData.tokens || 0);
        setFarmed(eweData.farmedTokens || 0);
        setIsFarming(eweData.isFarming || false);

        if (eweData.startTime) {
          const ms = parseAsUTC(eweData.startTime);
          setStartTime(isNaN(ms) ? null : ms);
        }
      })
      .catch((err) => {
        console.error('[EWE] /game_state fetch error:', err);
        setError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  // Handle offline progress
  useEffect(() => {
    if (isLoading || error) return;
    if (isFarming && startTime) {
      const now = Date.now();
      const elapsed = (now - startTime) / 1000;
      if (elapsed >= farmDuration) {
        setFarmed(maxTokens);
        setIsFarming(false);
        syncEweData({
          tokens,
          farmedTokens: maxTokens,
          isFarming: false,
          startTime: null,
        });
      } else {
        setFarmed((elapsed / farmDuration) * maxTokens);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, error]);

  // Update progress every second
  useEffect(() => {
    let interval = null;
    if (!isLoading && !error && isFarming && startTime) {
      interval = setInterval(() => {
        const now = Date.now();
        const elapsed = (now - startTime) / 1000;
        if (elapsed >= farmDuration) {
          setFarmed(maxTokens);
          setIsFarming(false);
          clearInterval(interval);
          syncEweData({
            tokens,
            farmedTokens: maxTokens,
            isFarming: false,
            startTime: null,
          });
        } else {
          setFarmed((elapsed / farmDuration) * maxTokens);
        }
      }, 1000);
    }
    return () => interval && clearInterval(interval);
  }, [isFarming, startTime, tokens, isLoading, error]);

  // Start farming
  const startFarming = () => {
    if (!isFarming) {
      const now = Date.now();
      log('startFarming => user clicked =>', new Date(now).toISOString());
      syncEweData({
        tokens,
        farmedTokens: 0,
        isFarming: true,
        startTime: now,
      });
      setIsFarming(true);
      setFarmed(0);
      setStartTime(now);
    }
  };

  // Collect if done
  const collectTokens = () => {
    if (farmed >= maxTokens) {
      const newTotal = +(tokens + farmed).toFixed(3);
      log('collect =>', { old: tokens, farmed, newTotal });
      syncEweData({
        tokens: newTotal,
        farmedTokens: 0,
        isFarming: false,
        startTime: null,
      });
      setTokens(newTotal);
      setFarmed(0);
      setIsFarming(false);
      setStartTime(null);
    }
  };

  // Single button => if done => collect, else => start
  const handleButtonClick = () => {
    if (!isFarming && farmed >= maxTokens) {
      collectTokens();
    } else if (!isFarming) {
      startFarming();
    }
  };

  // -----------------------------------------------------------------------
  // If UniverseSwitcher is already showing a loading screen, skip local
  // loading and error screens. As soon as UniverseSwitcher stops loading,
  // we fall back to the local logic below if we still haven't fetched data.
  // -----------------------------------------------------------------------
  if (fromUniverseSwitcherLoading) {
    // If you want to hide *all* local errors/empty data until the UniverseSwitcher
    // is done, simply return null or a barebones fallback:
    if (!gameState && !error) {
      return null; // “still fetching in background”
    }
    // If there's an error while UniverseSwitcher is loading, you could also
    // choose to do “return null;” or pass the error upward. For simplicity:
    if (error) {
      // Hide local error so UniverseSwitcher can show e.g. "Changing to EWE..."
      return null;
    }
    // Otherwise, if we have data at this point, show final UI:
    // (the user won't see partial screen behind UniverseSwitcher overlay).
  } else {
    // If UniverseSwitcher is NOT loading, handle local loading logic as usual:
    if (isLoading) {
      return (
        <div className="status-overlay">
          <video
            src={loadingVideo}
            className="loading-video"
            autoPlay
            loop
            muted
            playsInline
            webkit-playsinline="true"
            disablePictureInPicture
            controlsList="nodownload noremoteplayback"
            style={{ pointerEvents: 'none' }}
          />
          <div className="status-text">Loading EWE...</div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="status-overlay">
          <video
            src={loadingVideo}
            className="loading-video"
            autoPlay
            loop
            muted
            playsInline
            webkit-playsinline="true"
            disablePictureInPicture
            controlsList="nodownload noremoteplayback"
            style={{ pointerEvents: 'none' }}
          />
          <div className="status-text">Error loading EWE data: {error}</div>
        </div>
      );
    }

    if (!gameState) {
      return (
        <div className="status-overlay">
          <video
            src={loadingVideo}
            className="loading-video"
            autoPlay
            loop
            muted
            playsInline
            webkit-playsinline="true"
            disablePictureInPicture
            controlsList="nodownload noremoteplayback"
            style={{ pointerEvents: 'none' }}
          />
          <div className="status-text">No EWE data available.</div>
        </div>
      );
    }
  }

  // Final UI
  return (
    <div className="App1">
      <Background />
      <header className="header">
        <Score tokens={tokens} />
      </header>
      <div className="content">
        <CircularProgressBar value={farmed} maxValue={maxTokens} />
        <FarmButton
          isFarming={isFarming}
          farmedTokens={farmed}
          maxTokens={maxTokens}
          onClick={handleButtonClick}
        />
      </div>
    </div>
  );
}

export default Ewe;
