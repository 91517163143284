// EatsApp.js
import React, { useState, useEffect, useRef } from 'react';
import './EatsApp.css';
import tapMessages from './tapMessages';

import ReferralShare from './components/ReferralShare';
import ProgressBar from './components/ProgressBar';
import logo from '../public/logo.png';
import 'react-circular-progressbar/dist/styles.css';
import NotificationIcon from './components/Notifications';
import clickerImage from '../public/EWI.png';
import Ref from '../public/ref.png';
import Games from '../public/games.png';
import Upgrade from '../public/upgrade.png';
import Wallet from '../public/wallet.png';
import Birge from '../public/birge.png';
import Tasks from '../public/tasks.png';
import Background from './components/bg';
import UserLogo from './components/UserLogo';
import TasksTab from './components/TasksTab';
import LeaderboardPage from './components/LeaderboardPage';
import GiftTab from './components/GiftTab';
import DailyBonus from './components/DailyBonus';
import Energ from '../public/en.gif';
import DailyClaimIcon from '../public/calendar.gif';
import Tutorial from './components/tutorial';
import GiftBoxIcon from '../public/box.gif';
import UpgradeTab from './components/UpgradeTab';
import BoostTab from './components/BoostTab';
import UniverseData from './UniverseData';
import WalletPage from './components/WalletPage'; // NEW: WalletPage component

// IMPORT your local loading video
import loadingVideo from './Loading.mp4';

const DamageIndicator = ({ x, y, damage }) => (
  <div className="damage-indicator" style={{ left: x, top: y }}>
    +{damage}
  </div>
);

// ADD a new prop: fromUniverseSwitcherLoading (default false).
function EatsApp({ setIsTabOpen, fromUniverseSwitcherLoading = false }) {
  const [showTutorial, setShowTutorial] = useState(false);
  const [gameState, setGameState] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [activeTab, setActiveTab] = useState(null);
  const [isTabOpenState, setIsTabOpenState] = useState(false);
  const [showButtons, setShowButtons] = useState(true);

  const [isImageDistorted, setIsImageDistorted] = useState(false);
  const [tapMessage, setTapMessage] = useState('');
  const [damageIndicators, setDamageIndicators] = useState([]);

  const [clickCount, setClickCount] = useState(0);
  const [lastClickTime, setLastClickTime] = useState(0);
  const [lastMessageTime, setLastMessageTime] = useState(0);

  const [multiplierCountdown, setMultiplierCountdown] = useState(0);

  // Keep references to container and image for bounding-box checks
  const clickerContainerRef = useRef(null);
  const clickerImgRef = useRef(null);

  const sessionIdRef = useRef(null);
  const MAX_RIPPLES = 10;

  const isTouchDevice =
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;

  // Detect Android to shift .tabs up and adjust .total-e offset
  const isAndroid = /Android/i.test(navigator.userAgent);

  // For swipe detection inside tabs
  const [touchStartX, setTouchStartX] = useState(null);

  // [ADD] VFX toggle state: by default on
  const [vfxEnabled, setVfxEnabled] = useState(() => {
    const stored = localStorage.getItem('myVfxEnabled');
    return stored === null ? true : stored === 'true';
  });

  // [ADD] toggle function => flips localStorage & vfxEnabled
  const toggleVfx = () => {
    setVfxEnabled((prev) => {
      const newVal = !prev;
      localStorage.setItem('myVfxEnabled', String(newVal));
      return newVal;
    });
  };

  // -------------------------------------------
  //  Swipe to close tab
  // -------------------------------------------
  const handleTouchStartTab = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEndTab = (e) => {
    if (touchStartX === null) return;
    const touchEndX = e.changedTouches[0].clientX;
    const difference = touchStartX - touchEndX;

    // If AppleCatcher is in progress => block
    const appleCatcherInProgress = localStorage.getItem('appleCatcherInProgress') === 'true';
    if (appleCatcherInProgress) {
      setTouchStartX(null);
      return;
    }

    // If difference > 50 => swipe back
    if (difference > 50) {
      handleBackButtonClick();
    }
    setTouchStartX(null);
  };

  // -------------------------------------------
  //  On first load => tutorial
  // -------------------------------------------
  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');
    if (!hasVisitedBefore) {
      setShowTutorial(true);
      localStorage.setItem('hasVisitedBefore', 'true');
    }
  }, []);
  const handleTutorialClose = () => setShowTutorial(false);

  useEffect(() => {
    setTimeout(() => setShowButtons(true), 100);
  }, []);

  // -------------------------------------------
  //  Telegram WebApp expand
  // -------------------------------------------
  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
      document.documentElement.style.height = '100%';
      document.body.style.height = '100%';
      document.body.style.overflow = 'hidden';
    }
  }, []);

  // Show/hide Telegram back button
  useEffect(() => {
    if (window.Telegram?.WebApp?.BackButton) {
      if (isTabOpenState) {
        window.Telegram.WebApp.BackButton.show();
      } else {
        window.Telegram.WebApp.BackButton.hide();
      }
    }
  }, [isTabOpenState]);

  // Sync on page hide
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden' && sessionIdRef.current) {
        UniverseData.syncActions(sessionIdRef.current, true);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Telegram back button => close tab
  useEffect(() => {
    if (window.Telegram?.WebApp?.BackButton) {
      window.Telegram.WebApp.BackButton.onClick(handleBackButtonClick);
    }
    return () => {
      if (window.Telegram?.WebApp?.BackButton) {
        window.Telegram.WebApp.BackButton.offClick(handleBackButtonClick);
      }
    };
  }, []);

  // Expand again after loading
  useEffect(() => {
    if (!isLoading && window.Telegram?.WebApp) {
      setTimeout(() => {
        window.Telegram.WebApp.expand();
      }, 200);
    }
  }, [isLoading]);

  // Prevent multi-touch scroll
  useEffect(() => {
    const handleTouchMove = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  // -------------------------------------------
  //  On mount => get sessionId => fetch game_state
  // -------------------------------------------
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');
    if (!sessionId) {
      console.warn('[EatsApp] No session_id in URL => cannot load data');
      setIsLoading(false);
      return;
    }

    sessionIdRef.current = sessionId;
    localStorage.setItem('session_id', sessionId);
    console.log('[EatsApp] Found session_id:', sessionId);

    setIsLoading(true);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    fetch(`${backendUrl}/game_state?session_id=${sessionId}`)
      .then((res) => {
        if (!res.ok) throw new Error(`Expired session, code:${res.status}`);
        return res.json();
      })
      .then((data) => {
        const localData = UniverseData.initialize(data);

        // store it for consistency
        localData.session_id = sessionId;
        localData.sessionId = sessionId;

        setGameState({ ...localData });

        // end local AppleCatcher session if user closed mid-game
        localStorage.setItem('appleCatcherInProgress', 'false');
      })
      .catch((err) => {
        console.error('[EatsApp] /game_state fetch error:', err);
        setError(err.message);
      })
      .finally(() => setIsLoading(false));
  }, []);

  // Periodic sync every 10s
  useEffect(() => {
    const timer = setInterval(() => {
      if (!sessionIdRef.current) return;
      UniverseData.syncActions(sessionIdRef.current, false);
    }, 10000);
    return () => clearInterval(timer);
  }, []);

  // Sync on beforeunload
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (!sessionIdRef.current) return;
      UniverseData.syncActions(sessionIdRef.current, true);
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // ------------------------------------------------------------------------------------
  // Match server logic for regen by last_activity
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    const regenTimer = setInterval(() => {
      setGameState((prev) => {
        if (!prev?.ewi) return prev;

        const clone = JSON.parse(JSON.stringify(prev));
        const now = Date.now();
        const { energy, maxEnergy, regenRate } = clone.ewi;

        if (!clone.ewi.last_activity) {
          clone.ewi.last_activity = now;
          clone.ewi.leftover = 0;
          return clone;
        }

        const timeDiffSec = (now - clone.ewi.last_activity) / 1000;
        clone.ewi.leftover = clone.ewi.leftover ?? 0;
        clone.ewi.leftover += timeDiffSec * regenRate;
        const increments = Math.floor(clone.ewi.leftover);
        if (increments > 0) {
          clone.ewi.energy = Math.min(energy + increments, maxEnergy);
          clone.ewi.leftover -= increments;
        }
        clone.ewi.last_activity = now;

        // update UniverseData local copy
        UniverseData.getCurrentData().ewi.energy = clone.ewi.energy;
        UniverseData.getCurrentData().ewi.last_activity = clone.ewi.last_activity;
        UniverseData.getCurrentData().ewi.leftover = clone.ewi.leftover;

        return clone;
      });
    }, 1000);

    return () => clearInterval(regenTimer);
  }, []);

  // Check tap multiplier
  useEffect(() => {
    const interval = setInterval(() => {
      UniverseData.checkAndRevertTapMultiplier();
      setGameState((prev) => {
        if (!prev?.ewi) return prev;
        const clone = JSON.parse(JSON.stringify(prev));
        const updated = UniverseData.getCurrentData()?.ewi || {};

        clone.ewi.tapMultiplier = updated.tapMultiplier;
        clone.ewi.tapMultiplierEnd = updated.tapMultiplierEnd;

        if (clone.ewi.tapMultiplier > 1 && clone.ewi.tapMultiplierEnd) {
          const remaining = clone.ewi.tapMultiplierEnd - Date.now();
          const secs = remaining > 0 ? Math.ceil(remaining / 1000) : 0;
          setMultiplierCountdown(secs);
        } else {
          setMultiplierCountdown(0);
        }
        return clone;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // Show/hide bottom buttons
  useEffect(() => {
    setShowButtons(!isTabOpenState);
  }, [isTabOpenState]);

  // ------------------------------------------------
  //  handleTaskClaimFromParent => updates local EWI
  // ------------------------------------------------
  const handleTaskClaimFromParent = (taskIndex, reward, rewardType) => {
    UniverseData.handleTaskClaim(taskIndex, reward, rewardType);
    setGameState({ ...UniverseData.getCurrentData() });
  };

  // ------------------------------------------------
  //  handleDailyClaimFromParent => updates local EWI
  // ------------------------------------------------
  const handleDailyClaimFromParent = (dayNumber, rewardString) => {
    UniverseData.handleDailyClaim(dayNumber, rewardString);
    setGameState({ ...UniverseData.getCurrentData() });
  };

  // ------------------------------------------------
  //  AppleCatcher => bombs flow => re-fetch
  // ------------------------------------------------
  const handleBombGameEnded = async (finalScore) => {
    console.log('[EatsApp] handleBombGameEnded => finalScore=', finalScore);
    if (!sessionIdRef.current) return;
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    try {
      // final sync
      await UniverseData.syncActions(sessionIdRef.current, true);

      // re-fetch
      const res = await fetch(`${backendUrl}/game_state?session_id=${sessionIdRef.current}`);
      if (!res.ok) {
        throw new Error(`Error re-fetching game_state: ${res.status}`);
      }
      const updatedData = await res.json();

      UniverseData.initialize(updatedData);
      updatedData.session_id = sessionIdRef.current;
      updatedData.sessionId = sessionIdRef.current;
      setGameState({ ...updatedData });

      // end local AppleCatcher
      localStorage.setItem('appleCatcherInProgress', 'false');
    } catch (err) {
      console.error('[EatsApp] handleBombGameEnded error:', err);
    }
  };

  // ------------------------------------------------
  //  PurblePairs => re-fetch, same as bombs
  // ------------------------------------------------
  const handlePurblePairsGameEnded = async (finalScore) => {
    console.log('[EatsApp] handlePurblePairsGameEnded => finalScore=', finalScore);
    if (!sessionIdRef.current) return;
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    try {
      // final sync
      await UniverseData.syncActions(sessionIdRef.current, true);

      // re-fetch
      const res = await fetch(`${backendUrl}/game_state?session_id=${sessionIdRef.current}`);
      if (!res.ok) {
        throw new Error(`Error re-fetching game_state: ${res.status}`);
      }
      const updatedData = await res.json();

      UniverseData.initialize(updatedData);
      updatedData.session_id = sessionIdRef.current;
      updatedData.sessionId = sessionIdRef.current;
      setGameState({ ...updatedData });
    } catch (err) {
      console.error('[EatsApp] handlePurblePairsGameEnded error:', err);
    }
  };

  // --------------------------------------------
  //  handleBackButtonClick => close tabs
  // --------------------------------------------
  const handleBackButtonClick = () => {
    setActiveTab(null);
    setIsTabOpenState(false);
    setIsTabOpen?.(false);
    setShowButtons(true);
  };

  // --------------------------------------------
  //  handleTabOpen => sync + open
  // --------------------------------------------
  const handleTabOpen = (tab) => {
    if (sessionIdRef.current) {
      UniverseData.syncActions(sessionIdRef.current, true);
    }
    setActiveTab(tab);
    setIsTabOpenState(true);
    setIsTabOpen?.(true);
    setShowButtons(false);
  };

  // --------------------------------------------
  //  onNotificationsClaimed => re-fetch game_state
  // --------------------------------------------
  const onNotificationsClaimed = async (referralUserId, bonusEwi, bonusEwe) => {
    console.log(
      '[EatsApp] onNotificationsClaimed => referralUserId=',
      referralUserId,
      'EWI=',
      bonusEwi,
      'EWE=',
      bonusEwe
    );
    if (!sessionIdRef.current) return;
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/game_state?session_id=${sessionIdRef.current}`
      );
      if (res.ok) {
        const data = await res.json();
        UniverseData.initialize(data);

        data.session_id = sessionIdRef.current;
        data.sessionId = sessionIdRef.current;
        setGameState({ ...data });
      }
    } catch (err) {
      console.error('[onNotificationsClaimed] refresh error:', err);
    }
  };

  // --------------------------------------------
  //  Handling taps on main screen
  // --------------------------------------------
  const isClickOnImage = (x, y) => {
    if (!clickerImgRef.current) return false;
    const rect = clickerImgRef.current.getBoundingClientRect();
    return x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;
  };

  const handleClickDesktop = (e) => {
    e.preventDefault();
    if (!isClickOnImage(e.clientX, e.clientY)) return;
    processTap(e.clientX, e.clientY);
  };

  const handleTouchMobile = (e) => {
    e.preventDefault();
    for (let i = 0; i < e.touches.length; i++) {
      const touch = e.touches[i];
      if (!isClickOnImage(touch.clientX, touch.clientY)) continue;
      processTap(touch.clientX, touch.clientY);
    }
  };

  const processTap = (x, y) => {
    const data = UniverseData.getCurrentData();
    if (!data?.ewi) return;

    const { damageLevel = 1, tapMultiplier = 1 } = data.ewi;
    const totalDamage = damageLevel * tapMultiplier;

    const success = UniverseData.handleClick(totalDamage);
    if (!success) {
      setTapMessage('Not enough energy!');
      if (sessionIdRef.current) {
        UniverseData.syncActions(sessionIdRef.current, true);
      }
      return;
    }
    setGameState({ ...UniverseData.getCurrentData() });

    const now = Date.now();
    const timeDiff = now - lastClickTime;
    setLastClickTime(now);

    if (timeDiff < 500) {
      setClickCount((prev) => prev + 1);
    } else {
      setClickCount(1);
    }
    if (clickCount >= 8 && timeDiff <= 1000) {
      if (now - lastMessageTime >= 3000) {
        const msg = tapMessages[Math.floor(Math.random() * tapMessages.length)];
        setTapMessage(msg);
        setLastMessageTime(now);
      }
    } else {
      setTapMessage('');
    }

    // [ADD] Skip ripple+3D tilt if vfxEnabled = false
    if (!vfxEnabled) {
      // do minimal or no effect
    } else {
      // original ripple + 3D tilt
      const containerRect = clickerContainerRef.current.getBoundingClientRect();
      const centerX = containerRect.left + containerRect.width / 2;
      const centerY = containerRect.top + containerRect.height / 2;

      const deltaX = x - centerX;
      const deltaY = y - centerY;

      const angleX = (deltaY / containerRect.height) * -35;
      const angleY = (deltaX / containerRect.width) * 35;

      clickerContainerRef.current.style.transform =
        `rotateX(${angleX}deg) rotateY(${angleY}deg)`;

      setTimeout(() => {
        if (clickerContainerRef.current) {
          clickerContainerRef.current.style.transform = 'rotateX(0deg) rotateY(0deg)';
        }
      }, 200);

      const ripple = document.createElement('div');
      ripple.className = 'ripple';
      ripple.style.left = `${x - containerRect.left}px`;
      ripple.style.top = `${y - containerRect.top}px`;
      clickerContainerRef.current.appendChild(ripple);

      const existing = clickerContainerRef.current.querySelectorAll('.ripple');
      if (existing.length > MAX_RIPPLES) {
        existing[0].remove();
      }
      setTimeout(() => ripple.remove(), 800);
    }

    // floating damage text => always do
    const containerRect = clickerContainerRef.current.getBoundingClientRect();
    const newIndicator = {
      id: Date.now() + Math.random(),
      x: x - containerRect.left,
      y: y - containerRect.top,
      damage: totalDamage,
    };
    setDamageIndicators((prev) => {
      const next = [...prev, newIndicator];
      if (next.length > 25) next.shift();
      return next;
    });
    setTimeout(() => {
      setDamageIndicators((prev) => prev.filter((i) => i.id !== newIndicator.id));
    }, 1000);
  };

  // --------------------------------------------
  //  handleUpgrade / handleBoost
  // --------------------------------------------
  const handleUpgrade = (upgradeType, cost) => {
    const success = UniverseData.handleUpgrade(upgradeType, cost);
    if (!success) {
      setTapMessage('Not enough EWI!');
      return;
    }
    setGameState({ ...UniverseData.getCurrentData() });
  };

  const handleBoost = (boostType) => {
    UniverseData.handleBoost(boostType, false);
    const current = UniverseData.getCurrentData();
    if (current?.ewi) {
      if (boostType === 'maxEnergy') {
        current.ewi.dailyboostme = 1;
      } else if (boostType === 'tapMultiplier') {
        current.ewi.dailyboostmt = 1;
      }
    }
    setGameState({ ...current });
  };

  // Clear tapMessage after 3s
  useEffect(() => {
    if (tapMessage) {
      const t = setTimeout(() => setTapMessage(''), 3000);
      return () => clearTimeout(t);
    }
  }, [tapMessage]);

  // --------------------------------------------
  //  Render
  // --------------------------------------------
  if (!sessionIdRef.current && !gameState && !isLoading && !error) {
    if (fromUniverseSwitcherLoading) {
      return null;
    }
    return (
      <div className="status-overlay">
        <video
          src={loadingVideo}
          className="loading-video"
          autoPlay
          loop
          muted
          playsInline
          webkit-playsinline="true"
          disablePictureInPicture
          controlsList="nodownload noremoteplayback"
          style={{ pointerEvents: 'none' }}
        />
        <div className="status-text">
          No valid session ID found. Please open via Telegram Bot link:&nbsp;
          <a
            href="https://t.me/Green_Woods_bot"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#fff', textDecoration: 'underline' }}
          >
            @Green_Woods_bot
          </a>
        </div>
      </div>
    );
  }

  if (isLoading) {
    if (fromUniverseSwitcherLoading) {
      return null;
    }
    return (
      <div className="status-overlay">
        <video
          src={loadingVideo}
          className="loading-video"
          autoPlay
          loop
          muted
          playsInline
          webkit-playsinline="true"
          disablePictureInPicture
          controlsList="nodownload noremoteplayback"
          style={{ pointerEvents: 'none' }}
        />
        <div className="status-text">Loading...</div>
      </div>
    );
  }

  if (error) {
    if (fromUniverseSwitcherLoading) {
      return null;
    }
    return (
      <div className="status-overlay">
        <video
          src={loadingVideo}
          className="loading-video"
          autoPlay
          loop
          muted
          playsInline
          webkit-playsinline="true"
          disablePictureInPicture
          controlsList="nodownload noremoteplayback"
          style={{ pointerEvents: 'none' }}
        />
        <div className="status-text">Error: {error}</div>
      </div>
    );
  }

  if (!gameState) {
    if (fromUniverseSwitcherLoading) {
      return null;
    }
    return (
      <div className="status-overlay">
        <video
          src={loadingVideo}
          className="loading-video"
          autoPlay
          loop
          muted
          playsInline
          webkit-playsinline="true"
          disablePictureInPicture
          controlsList="nodownload noremoteplayback"
          style={{ pointerEvents: 'none' }}
        />
        <div className="status-text">No game data available</div>
      </div>
    );
  }

  // If we get here => we have gameState, no error
  const ewi = gameState.ewi || {};
  const energy = ewi.energy || 0;
  const maxEnergy = ewi.maxEnergy || 1000;
  const remainingEnergyPercentage = ((maxEnergy - energy) / maxEnergy) * 100;

  // [ADD] If vfxEnabled => normal; if not => "vfx-off" 
  const appClass = `App ${vfxEnabled ? '' : 'vfx-off'}`;

  return (
    <div className={appClass}>
      <Background vfxEnabled={vfxEnabled} />

      {showTutorial && <Tutorial onClose={handleTutorialClose} />}

      {/* TAB => tasks */}
      {isTabOpenState && activeTab === 'TASKS' && (
        <div className="tab-container" onTouchStart={handleTouchStartTab} onTouchEnd={handleTouchEndTab}>
          <TasksTab
            tasksState={gameState.tasks}
            handleTaskClaimFromParent={handleTaskClaimFromParent}
          />
        </div>
      )}
      {/* TAB => referral */}
      {isTabOpenState && activeTab === 'REFERRAL' && (
        <div className="tab-container" onTouchStart={handleTouchStartTab} onTouchEnd={handleTouchEndTab}>
          <ReferralShare />
        </div>
      )}
      {/* TAB => giftbox */}
      {isTabOpenState && activeTab === 'GIFTBOX' && (
        <div className="tab-container" onTouchStart={handleTouchStartTab} onTouchEnd={handleTouchEndTab}>
          <GiftTab />
        </div>
      )}
      {/* TAB => daily claim */}
      {isTabOpenState && activeTab === 'DAILY_CLAIM' && (
        <div className="tab-container" onTouchStart={handleTouchStartTab} onTouchEnd={handleTouchEndTab}>
          <DailyBonus onDailyClaimFromParent={handleDailyClaimFromParent} />
        </div>
      )}
      {/* TAB => leaderboard */}
      {isTabOpenState && activeTab === 'LEADERBOARD' && (
        <div className="tab-container" onTouchStart={handleTouchStartTab} onTouchEnd={handleTouchEndTab}>
          <LeaderboardPage onBack={handleBackButtonClick} />
        </div>
      )}
      {/* TAB => boost => bombs + purble pairs */}
      {isTabOpenState && activeTab === 'BOOST' && (
        <div className="tab-container" onTouchStart={handleTouchStartTab} onTouchEnd={handleTouchEndTab}>
          <BoostTab
            onBombGameEnded={handleBombGameEnded}
            // NEW: so purble pairs also re-fetches on end
            onPurblePairsGameEnded={handlePurblePairsGameEnded}
          />
        </div>
      )}
      {/* TAB => upgrades */}
      {isTabOpenState && activeTab === 'UPGRADE' && (
        <div className="tab-container" onTouchStart={handleTouchStartTab} onTouchEnd={handleTouchEndTab}>
          <UpgradeTab
            damageLevel={ewi.damageLevel || 1}
            energyLevel={ewi.energyLevel || 1}
            regenLevel={ewi.regenLevel || 1}
            currentEWI={ewi.currentEWI || 0}
            handleUpgrade={handleUpgrade}
            handleBoost={handleBoost}
            onCloseUpgrades={handleBackButtonClick}
            dailyBoostMe={ewi.dailyboostme || 0}
            dailyBoostMt={ewi.dailyboostmt || 0}
            tonLeft={gameState?.user?.ton_left || 0}
            tonIsFarming={gameState?.user?.ton_isfarming || false}
            tonEwiFarmed={gameState?.user?.ton_ewi_farmed || 0}
            onAutoFarmComplete={() => {
              // update from UniverseData
              setGameState({ ...UniverseData.getCurrentData() });
            }}
          />
        </div>
      )}
      {/* TAB => wallet */}
      {isTabOpenState && activeTab === 'WALLET' && (
        <div className="tab-container" onTouchStart={handleTouchStartTab} onTouchEnd={handleTouchEndTab}>
          <WalletPage
            onBack={handleBackButtonClick}
            userId={gameState.user && gameState.user.user_id}
          />
        </div>
      )}

      {/* Main “clicker” screen when no tab is open */}
      {!isTabOpenState && (
        <header className="App-header">
          <NotificationIcon
            userData={gameState}
            onNotificationsClaimed={onNotificationsClaimed}
            onTabOpen={handleTabOpen}
          />

          {/* Top bar with user icon, energy, progress */}
          <div className="up-cont">
            <div className="bs1" onClick={() => handleTabOpen('LEADERBOARD')}>
              <UserLogo defaultLogo={logo} />
            </div>

            <div className="energy-container">
              <p className="Energy-descr">{Math.floor(energy)}</p>
              <img src={Energ} alt="Energy Icon" className="energy-icon" />
            </div>

            <div className="total-e">
              <ProgressBar remainingEnergyPercentage={remainingEnergyPercentage} />
            </div>
          </div>

          {/* tasks/gift/daily claims row */}
          <div className="tasks-icon-container">
            <button onClick={() => handleTabOpen('TASKS')}>
              <img className="icon" src={Tasks} alt="Tasks" />
            </button>
            <button className="disabled">
              <img className="icon" src={GiftBoxIcon} alt="Gift Box" />
            </button>
            <button onClick={() => handleTabOpen('DAILY_CLAIM')}>
              <img className="icon" src={DailyClaimIcon} alt="Daily Claim" />
            </button>
          </div>

          {/* EWI balance */}
          <div className="balance-container">
            <img src={clickerImage} alt="Balance Icon" className="balance-icon" />
            <p>{Math.floor(ewi.currentEWI || 0)}</p>
          </div>

          {/* tap multiplier or message */}
          {ewi.tapMultiplier > 1 && multiplierCountdown > 0 && (
            <div className="tap-message">
              Tap x{ewi.tapMultiplier} active: {multiplierCountdown}s left
            </div>
          )}
          {(ewi.tapMultiplier === 1 || !ewi.tapMultiplier) && tapMessage && (
            <div className="tap-message">{tapMessage}</div>
          )}

          {/* main clicker container */}
          <div
            className="clicker-container"
            ref={clickerContainerRef}
            onClick={!isTouchDevice ? handleClickDesktop : undefined}
            onTouchStart={isTouchDevice ? handleTouchMobile : undefined}
          >
            <img
              src={clickerImage}
              alt="Clicker"
              className={`clicker-image ${isImageDistorted ? 'distorted' : ''}`}
              ref={clickerImgRef}
              style={{
                userSelect: 'none',
                WebkitUserSelect: 'none',
                WebkitTouchCallout: 'none',
              }}
              onContextMenu={(e) => e.preventDefault()}
            />

            {damageIndicators.map((indicator) => (
              <DamageIndicator
                key={indicator.id}
                x={indicator.x}
                y={indicator.y}
                damage={indicator.damage}
              />
            ))}
          </div>
        </header>
      )}

      {/* [ADD] Floating button that toggles vfxEnabled on/off */}
      {!isTabOpenState && (
        <div
          style={{
            position: 'fixed',
            left: '10px',
            bottom: isAndroid ? '150px' : '90px',
            zIndex: 999
          }}
        >
          <button
            style={{
              padding: '8px 12px',
              fontSize: '14px',
              cursor: 'pointer',
              backgroundColor: '#333',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              opacity: 0.8
            }}
            onClick={toggleVfx}
          >
            {vfxEnabled ? 'Disable VFX' : 'Enable VFX'}
          </button>
        </div>
      )}

      {/* Bottom tabs row */}
      {!isTabOpenState && showButtons && (
        <div className={`tabs ${isAndroid ? 'tabs-android' : ''}`}>
          <button onClick={() => handleTabOpen('REFERRAL')}>
            <img className="icon" src={Ref} alt="Ref" />
          </button>
          <button onClick={() => handleTabOpen('WALLET')}>
            <img className="icon" src={Wallet} alt="Wallet" />
          </button>
          <button onClick={() => handleTabOpen('BOOST')}>
            <img className="icon" src={Games} alt="Games" />
          </button>
          <button className="disabled">
            <img className="icon" src={Birge} alt="Exchange" />
          </button>
          <button onClick={() => handleTabOpen('UPGRADE')}>
            <img className="icon" src={Upgrade} alt="Upgrade" />
          </button>
        </div>
      )}
    </div>
  );
}

export default EatsApp;
