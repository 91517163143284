// WalletPage.js
import React, { useState, useEffect } from "react";
import { Modal, Typography } from "@mui/material";
import {
  CHAIN,
  useTonAddress,
  useTonConnectUI
} from "@tonconnect/ui-react";
import "../css.components/WalletPage.css";

function WalletPage() {
  // Get the raw on-chain address from TonConnect (e.g. "0:1b1234abcdef0jfC")
  const rawAddress = useTonAddress("");
  // Get the TonConnect UI instance
  const [tonConnectUI] = useTonConnectUI();

  const [donationAmount, setDonationAmount] = useState("1");
  const [message, setMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  // Transactions / totals
  const [tonTransactions, setTonTransactions] = useState([]);
  const [tonTotal, setTonTotal] = useState(0);
  const [didFetch, setDidFetch] = useState(false);

  // Your backend URL
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "";

  // Convert raw address into a full "UQ" address (e.g. "UQ1b1234abcdef0jfC")
  function getUQAddress(rawAddr) {
    if (!rawAddr) return "";
    const noPrefix = rawAddr.replace(/^0:/, "");
    return "UQ" + noPrefix;
  }

  const fullUQAddress = getUQAddress(rawAddress);

  // Create a short version of the address for display
  let shortAddress = "";
  if (fullUQAddress.length > 8) {
    shortAddress =
      fullUQAddress.slice(0, 6) + "..." + fullUQAddress.slice(-6);
  } else {
    shortAddress = fullUQAddress;
  }

  // Connect handler: opens TonConnect wallet selector with only allowed wallets,
  // and disables the "View all wallets" option (if supported)
  const handleConnect = async () => {
    try {
      await tonConnectUI.connectWallet({
        wallets: [
          "tonkeeper",
          "tonwallet",
          "telegram",
          "tonhub"
        ],
        showViewAll: false // This flag may not be supported in all versions.
      });
    } catch (err) {
      console.error("Failed to connect wallet", err);
      setMessage("Failed to connect wallet");
      setModalOpen(true);
    }
  };

  // Show full address modal on click
  const handleShowFullAddress = () => {
    if (fullUQAddress) {
      setMessage(`Your wallet address: ${fullUQAddress}`);
      setModalOpen(true);
    }
  };

  // Convert TON to nanoton
  const tonToNanoton = (value) => {
    const floatVal = parseFloat(value);
    return isNaN(floatVal) ? 0 : Math.floor(floatVal * 1e9);
  };

  // Fetch existing TON transactions & total from server
  useEffect(() => {
    let ignore = false;

    const fetchTonData = async () => {
      if (ignore || didFetch) return;

      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get("session_id");
      if (!sessionId) {
        console.warn("[WalletPage] No session_id => cannot fetch TON transactions");
        return;
      }

      try {
        const response = await fetch(`${BACKEND_URL}/ton_transactions?session_id=${sessionId}`);
        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }

        const data = await response.json();
        setTonTransactions(data.transactions || []);
        setTonTotal(data.total || 0);
      } catch (error) {
        console.error("Error fetching TON transactions:", error);
      } finally {
        setDidFetch(true);
      }
    };

    fetchTonData();
    return () => {
      ignore = true;
    };
  }, [didFetch, BACKEND_URL]);

  // Donate handler
  const handleDonate = async (e) => {
    e.preventDefault();
    try {
      // Ensure the wallet is connected and on MAINNET
      if (!tonConnectUI.account || tonConnectUI.account.chain !== CHAIN.MAINNET) {
        setMessage("Please switch your wallet to MAINNET.");
        setModalOpen(true);
        return;
      }

      const nanotonAmount = tonToNanoton(donationAmount);
      const minNanoton = 0.5 * 1e9;
      if (nanotonAmount < minNanoton) {
        setMessage("Minimum donation is 0.5 TON.");
        setModalOpen(true);
        return;
      }

      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get("session_id") || "";

      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 360,
        network: CHAIN.MAINNET,
        messages: [
          {
            address: "UQBkeS4Sl2NRC18tU7WWe3wVCy5x8jk3jkEpma4f9_e7xnEx",
            amount: nanotonAmount
          }
        ]
      };

      const result = await tonConnectUI.sendTransaction(transaction);

      if (result && result.boc) {
        setMessage("Transaction was successful!");
        setModalOpen(true);

        // POST donation info to your server
        try {
          const response = await fetch(`${BACKEND_URL}/ton_donation`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              session_id: sessionId,
              amount: parseFloat(donationAmount),
              boc: result.boc
            })
          });

          if (!response.ok) {
            console.error("Donation server error:", await response.json());
          } else {
            // fetch again to update tonTotal
            setDidFetch(false);
          }
        } catch (err) {
          console.error("Failed to POST donation to server:", err);
        }
      }
    } catch (error) {
      console.error("Transaction failed", error);
      setMessage("Transaction failed");
      setModalOpen(true);
    }
  };

  // Format the total with a maximum of 5 decimals
  const formattedTotal = Number(tonTotal.toFixed(5));

  // Disconnect handler
  const handleDisconnect = () => {
    try {
      tonConnectUI.disconnect();
      setMessage("Wallet disconnected");
      setModalOpen(true);
    } catch (err) {
      console.error("Failed to disconnect wallet:", err);
      setMessage("Failed to disconnect wallet");
      setModalOpen(true);
    }
  };

  // Use MutationObserver to hide the "View all wallets" button from the modal.
  // This observer checks the document body for any elements matching the selectors
  // and sets their display to none.
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const viewAllButton = document.querySelector(
        ".tonconnect-modal__view-all, .view-all-wallets, button[class*='viewAll']"
      );
      if (viewAllButton) {
        viewAllButton.style.display = "none";
      }
    });
    observer.observe(document.body, { childList: true, subtree: true });
    return () => observer.disconnect();
  }, []);

  return (
    <div className="wallet-page">
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="modal-content">
          <Typography variant="body1">{message}</Typography>
        </div>
      </Modal>

      <div className="wallet-content">
        <div className="wallet-title-container">
          <h1 className="wallet-title">TON Donation</h1>
        </div>

        <div className="link-section">
          <h2>
            Your TON Donations
            {fullUQAddress && (
              <span
                className="short-address"
                onClick={handleShowFullAddress}
                style={{ cursor: "pointer", marginLeft: "10px", fontSize: "0.8rem" }}
              >
                ({shortAddress})
              </span>
            )}
          </h2>
          <p className="total-donated">
            Total Donated: <strong>{formattedTotal} TON</strong>
          </p>

          {fullUQAddress !== "" && (
            <div className="donation-container">
              <input
                type="number"
                className="donation-input"
                placeholder="Enter TON amount"
                min="0.5"
                step="0.001"
                value={donationAmount}
                onChange={(e) => setDonationAmount(e.target.value)}
              />
              <button className="donate-button" onClick={handleDonate}>
                Donate
              </button>
            </div>
          )}
          {fullUQAddress === "" && (
            <p style={{ textAlign: "center", marginTop: "1rem" }}>
              Please connect your wallet to donate.
            </p>
          )}
        </div>

        <div className="transactions-section">
          <h2>Transactions ({tonTransactions.length})</h2>
          <div className="transactions-list">
            {tonTransactions.map((tx, index) => {
              const formattedDate = tx.date
                ? new Date(tx.date).toLocaleString(undefined, {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false
                  })
                : "N/A";

              return (
                <a
                  key={index}
                  className="transaction-item"
                  href={`https://tonviewer.com/transaction/${tx.txHash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="transaction-amount">{tx.amount} TON</span>
                  {tx.isscam && (
                    <span className="scam-alert">Contact admin to verify</span>
                  )}
                  <span className="transaction-date">{formattedDate}</span>
                </a>
              );
            })}
          </div>
        </div>
      </div>

      {/* Show Connect button if wallet is not connected */}
      {fullUQAddress === "" && (
        <button
          className="wallet-fixed-button connect-button"
          onClick={handleConnect}
        >
          Connect Wallet
        </button>
      )}

      {/* Show Disconnect button if wallet is connected */}
      {fullUQAddress !== "" && (
        <button
          className="wallet-fixed-button disconnect"
          onClick={handleDisconnect}
        >
          Disconnect Wallet
        </button>
      )}
    </div>
  );
}

export default WalletPage;
