import React, { useEffect, useState } from "react";
import "../css.components/LeaderboardPage.css";

// Helper function to format TON values according to your rules
function formatTon(rawValue) {
  if (!Number.isFinite(rawValue)) return "0";
  const ton = Number(rawValue);
  const absTon = Math.abs(ton);

  // 1) Less than 100 => up to 4 decimals
  if (absTon < 100) {
    return ton.toFixed(2);
  }

  // 2) 100 to <1,000 => 1 decimal
  if (absTon < 1000) {
    return ton.toFixed(1);
  }

  // 3) 1,000 to <10,000 => no decimals
  if (absTon < 10_000) {
    return Math.round(ton).toString();
  }

  // 4) 10,000 to <1,000,000 => 'XXK'
  if (absTon < 1_000_000) {
    // e.g. 10,000 => "10K", 999,999 => "999K"
    const kValue = Math.floor(absTon / 1000);
    return (ton < 0 ? "-" : "") + kValue + "K";
  }

  // -------------------------------------
  // 5) 1,000,000+ => short-scale notation
  // -------------------------------------
  const sign = ton < 0 ? "-" : "";

  // A small helper to handle one decimal + strip trailing .0
  function shortScale(value, suffix) {
    let result = value.toFixed(1); // e.g. "1.0", "1.5"
    if (result.endsWith(".0")) {
      // remove trailing .0
      result = result.slice(0, -2);
    }
    return sign + result + suffix;
  }

  // 1,000,000 to <1,000,000,000 => "X.xMill"
  if (absTon < 1_000_000_000) {
    const mValue = absTon / 1_000_000;
    return shortScale(mValue, "Mill");
  }

  // 1,000,000,000 to <1,000,000,000,000 => "X.xBill"
  if (absTon < 1_000_000_000_000) {
    const bValue = absTon / 1_000_000_000;
    return shortScale(bValue, "Bill");
  }

  // 1,000,000,000,000 to <1,000,000,000,000,000 => "X.xTrill"
  if (absTon < 1_000_000_000_000_000) {
    const tValue = absTon / 1_000_000_000_000;
    return shortScale(tValue, "Trill");
  }

  // 1,000,000,000,000,000 to <1e18 => "X.xQuadr"
  if (absTon < 1_000_000_000_000_000_000) {
    const qValue = absTon / 1_000_000_000_000_000;
    return shortScale(qValue, "Quadr");
  }

  // 1e18 to <1e21 => "X.xQuint"
  if (absTon < 1e21) {
    const quintValue = absTon / 1e18;
    return shortScale(quintValue, "Quint");
  }

  // 1e21+ => "X.xSext"
  const sextValue = absTon / 1e21;
  return shortScale(sextValue, "Sext");
}

function LeaderboardPage() {
  const [leaderboard, setLeaderboard] = useState([]);

  // Show only the first "token" (first word) of the name
  const formatName = (name) => {
    if (!name) return "";
    const m = name.trim().match(/^(\S+)/);
    return m ? m[1] : "";
  };

  useEffect(() => {
    async function fetchLeaderboard() {
      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL || "";
        const response = await fetch(`${backendUrl}/leaderboard`);
        if (!response.ok) {
          throw new Error("Failed to fetch leaderboard data");
        }
        const data = await response.json();
        // Sort descending by TON donated.
        const sorted = data.leaderboard.sort((a, b) => b.ton - a.ton);
        setLeaderboard(sorted);
      } catch (error) {
        console.error("Error fetching leaderboard:", error);
      }
    }
    fetchLeaderboard();
  }, []);

  // Divide into sections: top 3, extended podium (4–7), others (8+).
  const topThree = leaderboard.slice(0, 3);
  const extended = leaderboard.slice(3, 7);
  const others = leaderboard.slice(7);

  return (
    <div className="leaderboard-page">
      <div className="leaderboard-content">
        <h1 className="leaderboard-title">Leaderboard</h1>

        {/* Top Three Section (Triangle Layout) */}
        <div className="top-three-container">
          {topThree[0] && (
            <div className="top-item rank1">
              <div className="avatar-container">
                <div className="avatar">
                  {topThree[0].telegram_name.charAt(0)}
                </div>
                <div className="rank-badge top-badge rank1-badge">1</div>
              </div>
              <div className="name">{formatName(topThree[0].telegram_name)}</div>
              <div className="donation">{formatTon(topThree[0].ton)} TON</div>
            </div>
          )}
          {topThree[1] && (
            <div className="top-item rank2">
              <div className="avatar-container">
                <div className="avatar">
                  {topThree[1].telegram_name.charAt(0)}
                </div>
                <div className="rank-badge top-badge rank2-badge">2</div>
              </div>
              <div className="name">{formatName(topThree[1].telegram_name)}</div>
              <div className="donation">{formatTon(topThree[1].ton)} TON</div>
            </div>
          )}
          {topThree[2] && (
            <div className="top-item rank3">
              <div className="avatar-container">
                <div className="avatar">
                  {topThree[2].telegram_name.charAt(0)}
                </div>
                <div className="rank-badge top-badge rank3-badge">3</div>
              </div>
              <div className="name">{formatName(topThree[2].telegram_name)}</div>
              <div className="donation">{formatTon(topThree[2].ton)} TON</div>
            </div>
          )}
        </div>

        {/* Extended Podium (Ranks 4–7) */}
        {extended.length > 0 && (
          <div className="extended-podium">
            {extended.map((item, idx) => (
              <div key={idx} className="extended-item">
                <div className="avatar-container">
                  <div className="avatar">
                    {item.telegram_name.charAt(0)}
                  </div>
                  <div className="rank-badge extended-badge">
                    {idx + 4}
                  </div>
                </div>
                <div className="name">{formatName(item.telegram_name)}</div>
                <div className="donation">{formatTon(item.ton)} TON</div>
              </div>
            ))}
          </div>
        )}

        {/* Others Section (Ranks 8+): Scrollable List */}
        {others.length > 0 && (
          <div className="others-section">
            <h2 className="holders-title">Top Holders</h2>
            <div className="holders-list">
              {others.map((item, idx) => (
                <div key={idx} className="other-item">
                  <span className="rank-number">{idx + 8}</span>
                  <div className="avatar">
                    {item.telegram_name.charAt(0)}
                  </div>
                  <div className="name">{formatName(item.telegram_name)}</div>
                  <div className="donation">{formatTon(item.ton)} TON</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LeaderboardPage;
