// PurblePairs.js
import React, { useState, useEffect } from 'react';
import './PurblePairs.css';
import UniverseData from '../../UniverseData';

// Front images
import img1 from './img/1.jpeg';
import img2 from './img/2.jpeg';
import img3 from './img/3.jpeg';
import img4 from './img/4.jpeg';
import img5 from './img/5.jpeg';
import img6 from './img/6.jpeg';
import img7 from './img/7.jpeg';
import img8 from './img/8.jpeg';

// Card back image
import backImg from './img/back.png';

// Base collection (front images)
const baseCollection = [
  { id: 1, art: img1 },
  { id: 2, art: img2 },
  { id: 3, art: img3 },
  { id: 4, art: img4 },
  { id: 5, art: img5 },
  { id: 6, art: img6 },
  { id: 7, art: img7 },
  { id: 8, art: img8 },
];

// Simple shuffle
const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);

const PurblePairs = ({ onGameOver }) => {
  const [cards, setCards] = useState([]);
  const [flippedCards, setFlippedCards] = useState([]);
  const [matchedPairs, setMatchedPairs] = useState([]);
  const [score, setScore] = useState(0);

  const [isGameStarted, setIsGameStarted] = useState(false);
  const [timer, setTimer] = useState(60);
  const [showResultModal, setShowResultModal] = useState(false);
  const [resultText, setResultText] = useState('');
  const [showFinalOk, setShowFinalOk] = useState(false);

  // Count mistakes & prevent flipping while checking
  const [wrongMatches, setWrongMatches] = useState(0);
  const [disableFlip, setDisableFlip] = useState(false);

  // Extra boolean to hide Start button after game over
  const [isGameOver, setIsGameOver] = useState(false);

  // Session tracking / moves log
  const [sessionId, setSessionId] = useState(null);
  const [movesLog, setMovesLog] = useState([]);

  // -----------------------------------------------
  // Shuffle deck on mount
  // -----------------------------------------------
  useEffect(() => {
    const newDeck = shuffleArray(
      [...baseCollection, ...baseCollection].map((card) => ({
        ...card,
        isFlipped: false,
        isMatched: false,
      }))
    );
    setCards(newDeck);
  }, []);

  // -----------------------------------------------
  // Timer effect
  // -----------------------------------------------
  useEffect(() => {
    let interval;
    if (isGameStarted && timer > 0) {
      interval = setInterval(() => setTimer((prev) => prev - 1), 1000);
    } else if (timer === 0 && isGameStarted) {
      // time up => lose
      handleGameEnd(false, 'Time is up!');
    }
    return () => clearInterval(interval);
  }, [isGameStarted, timer]);

  // -----------------------------------------------
  // Check if all pairs matched
  // -----------------------------------------------
  useEffect(() => {
    if (matchedPairs.length === baseCollection.length && isGameStarted) {
      handleGameEnd(true, '');
    }
  }, [matchedPairs, isGameStarted]);

  // -----------------------------------------------
  // Start Game
  // -----------------------------------------------
  const startGame = () => {
    resetGame();
    const newSessionId = `purble_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
    setSessionId(newSessionId);
    setIsGameStarted(true);

    // Log start
    UniverseData.actionQueue.push({
      type: 'purblePairs_start',
      data: {
        session_id: newSessionId,
        start_time: Date.now(),
      },
    });
    UniverseData.syncActions();
  };

  // -----------------------------------------------
  // Reset
  // -----------------------------------------------
  const resetGame = () => {
    // If you want the user to be able to replay multiple times (once the game ends),
    // you can revert 'isGameOver' to false. If you truly never want the button to re-appear,
    // keep isGameOver = true after finishing.
    setIsGameOver(false);

    setIsGameStarted(false);
    setTimer(60);
    setScore(0);
    setMatchedPairs([]);
    setFlippedCards([]);
    setWrongMatches(0);
    setDisableFlip(false);
    setShowResultModal(false);
    setResultText('');
    setShowFinalOk(false);
    setMovesLog([]);
    setSessionId(null);

    const newDeck = shuffleArray(
      [...baseCollection, ...baseCollection].map((card) => ({
        ...card,
        isFlipped: false,
        isMatched: false,
      }))
    );
    setCards(newDeck);
  };

  // -----------------------------------------------
  // Flip card
  // -----------------------------------------------
  const handleCardClick = (index) => {
    if (!isGameStarted || disableFlip) return;

    const newCards = [...cards];
    const card = newCards[index];
    if (card.isFlipped || card.isMatched) return;

    // Flip it
    card.isFlipped = true;
    setCards(newCards);

    // Log
    setMovesLog((prev) => [
      ...prev,
      {
        action: 'flip',
        cardIndex: index,
        cardId: card.id,
        timestamp: Date.now(),
      },
    ]);

    const newFlipped = [...flippedCards, index];
    setFlippedCards(newFlipped);

    if (newFlipped.length === 2) {
      setDisableFlip(true);

      const [firstIndex, secondIndex] = newFlipped;
      const firstCard = newCards[firstIndex];
      const secondCard = newCards[secondIndex];

      // match check
      if (firstCard.id === secondCard.id) {
        // matched
        firstCard.isMatched = true;
        secondCard.isMatched = true;
        setMatchedPairs((prev) => [...prev, firstCard.id]);
        setScore((prevScore) => prevScore + 10);

        setMovesLog((prev) => [
          ...prev,
          {
            action: 'match',
            card1Index: firstIndex,
            card1Id: firstCard.id,
            card2Index: secondIndex,
            card2Id: secondCard.id,
            isMatch: true,
            timestamp: Date.now(),
          },
        ]);

        setFlippedCards([]);
        setDisableFlip(false);
      } else {
        // not matched => flip back
        setMovesLog((prev) => [
          ...prev,
          {
            action: 'match',
            card1Index: firstIndex,
            card1Id: firstCard.id,
            card2Index: secondIndex,
            card2Id: secondCard.id,
            isMatch: false,
            timestamp: Date.now(),
          },
        ]);
        setWrongMatches((prev) => prev + 1);

        setTimeout(() => {
          firstCard.isFlipped = false;
          secondCard.isFlipped = false;
          setCards([...newCards]);
          setFlippedCards([]);
          setDisableFlip(false);
        }, 800);
      }
    }
  };

  // -----------------------------------------------
  // End game
  // -----------------------------------------------
  const handleGameEnd = (didWin, textOverride) => {
    if (!isGameStarted) return;
    setIsGameStarted(false);
    setIsGameOver(true); // hide start button

    // final formula
    const leftoverTime = timer;
    let finalScore = score + (leftoverTime * 50) - (wrongMatches * 2);
    if (finalScore < 0) finalScore = 0;
    if (finalScore > 1000) finalScore = 1000;

    setScore(finalScore);

    const msg = didWin
      ? `Congratulations! You matched all pairs.\nFinal Score: ${finalScore}`
      : textOverride
        ? `${textOverride}\nFinal Score: ${finalScore}`
        : `Game Over!\nFinal Score: ${finalScore}`;

    setResultText(msg);
    setShowResultModal(true);
    setShowFinalOk(true);

    // log final
    UniverseData.setGameSessionScore(finalScore);
    UniverseData.actionQueue.push({
      type: 'purblePairs_end',
      data: {
        session_id: sessionId,
        end_time: Date.now(),
        final_score: finalScore,
        moves: movesLog,
      },
    });
    UniverseData.syncActions(null, true);
  };

  // -----------------------------------------------
  // Close final result => parent can refresh EWI
  // -----------------------------------------------
  const handleResultClose = () => {
    setShowResultModal(false);
    setShowFinalOk(false);

    if (typeof onGameOver === 'function') {
      onGameOver(score);
    }
  };

  return (
    <div className="purble-pairs">
      <div className="game-header">
        <div className="timer">
          Time: <span>{timer}</span>
        </div>
        <div className="score">
          Score: <span>{score}</span>
        </div>
      </div>

      {/* Show "Start Game" button ONLY if not started AND not already ended */}
      {!isGameStarted && !isGameOver && (
        <button className="btn-game" onClick={startGame}>
          Start Game
        </button>
      )}

      <div className="cards">
        <div className="cards-grid">
          {cards.map((card, index) => (
            <div
              key={index}
              className={`card 
                ${card.isFlipped ? 'flipped' : ''} 
                ${card.isMatched ? 'matched' : ''}`}
              onClick={() => handleCardClick(index)}
            >
              {/* BACK: visible by default (rotateY(0)) */}
              <div className="card-back">
                <img className="card-img" src={backImg} alt="Back" />
              </div>

              {/* FRONT: hidden at rotateY(180) */}
              <div className="card-front">
                <img className="card-img" src={card.art} alt={`Card ${card.id}`} />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Final message box */}
      {showResultModal && (
        <div className="message-box">
          <p>{resultText}</p>
          {showFinalOk && (
            <button onClick={handleResultClose}>OK</button>
          )}
        </div>
      )}
    </div>
  );
};

export default PurblePairs;
