// Notifications.js
import React, { useState, useEffect } from 'react';
import '../css.components/Notifications.css';
import not from '../../public/not.gif';

//
// NotificationsList:
// Renders the “page-like” notifications layout with a Telegram Back button and swipe-to-close.
//
const NotificationsList = ({ notifications, onClose, onClaim }) => {
  // Prevent scrolling behind the page (optional—could be removed if not needed)
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    return () => {
      document.body.style.overflow = '';
      document.body.style.position = '';
    };
  }, []);

  // Telegram WebApp Back Button logic
  useEffect(() => {
    if (window.Telegram?.WebApp?.BackButton) {
      window.Telegram.WebApp.BackButton.show();
      const handleBackClick = () => onClose();
      window.Telegram.WebApp.BackButton.onClick(handleBackClick);

      return () => {
        window.Telegram.WebApp.BackButton.hide();
        window.Telegram.WebApp.BackButton.offClick(handleBackClick);
      };
    }
  }, [onClose]);

  // Swipe-to-close logic
  const [touchStartX, setTouchStartX] = useState(null);

  const handleTouchStartTab = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEndTab = (e) => {
    if (touchStartX === null) return;
    const touchEndX = e.changedTouches[0].clientX;
    const difference = touchStartX - touchEndX;

    // If difference > 50 => swipe close
    if (difference > 50) {
      onClose();
    }
    setTouchStartX(null);
  };

  return (
    <div
      className="notifications-fullscreen"
      onTouchStart={handleTouchStartTab}
      onTouchEnd={handleTouchEndTab}
    >
      <div className="notifications-content">
        <div className="notifications-title-container">
          <h1 className="notifications-title">Notifications</h1>
        </div>

        <div className="notifications-section">
          <div className="notifications-list">
            {notifications
              .filter((notif) => !notif.claimed)
              .map((notif) => (
                <div key={notif.id} className="notification-item">
                  <div className="notification-icon-emoji">🎉</div>

                  <div className="notification-details">
                    <p className="notification-message">{notif.message}</p>
                    <p className="notification-bonus">
                      +{notif.bonus_ewi?.toLocaleString()} $EWI
                      <br />
                      +{notif.bonus_ewe?.toLocaleString()} $EWE
                    </p>
                  </div>

                  <button
                    className="claim-button"
                    // Pass both notif.id and notif.referral_user_id
                    onClick={() => onClaim(notif.id, notif.referral_user_id)}
                  >
                    Claim
                  </button>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

//
// NotificationIcon:
// Displays the small notification icon with a badge, fetches notifications, and
// opens the full “NotificationsList” when clicked.
//
const NotificationIcon = ({ userData, onNotificationsClaimed, onTabOpen }) => {
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [hasFetched, setHasFetched] = useState(false); // ensures we only fetch once

  // 1) Extract session_id from userData
  const sessionId = userData?.session_id;

  // 2) Fetch notifications => GET /notifications?session_id=XXX
  const fetchNotifications = async () => {
    if (!sessionId) return;
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/notifications?session_id=${sessionId}`;
      const res = await fetch(url, { method: 'GET' });
      const data = await res.json();
      if (!res.ok) {
        console.error('[NotificationIcon] fetchNotifications error:', res.status, data);
        return;
      }
      if (data.notifications) {
        setNotifications(data.notifications);
      } else {
        console.warn('[NotificationIcon] No notifications in response =>', data);
      }
    } catch (err) {
      console.error('[NotificationIcon] fetchNotifications exception:', err);
    }
  };

  // 3) Fetch notifications once per session
  useEffect(() => {
    if (!hasFetched && userData && sessionId) {
      fetchNotifications();
      setHasFetched(true);
    }
  }, [userData, sessionId, hasFetched]);

  // 4) Claim reward => POST /claim_bonus => { session_id, notification_id, referral_user_id }
  const handleClaimReward = async (notifId, referralUserId) => {
    if (!sessionId) return;

    try {
      const body = {
        session_id: sessionId,
        notification_id: notifId,
        referral_user_id: referralUserId, // can be null or a number
      };
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/claim_bonus`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });
      const result = await res.json();

      if (!res.ok) {
        console.error('[NotificationIcon] claimReward error =>', res.status, result);
        return;
      }
      console.log('[NotificationIcon] claimReward success =>', result);

      // Mark notification claimed in local state
      setNotifications((prev) =>
        prev.map((notif) =>
          notif.id === notifId
            ? { ...notif, claimed: true }
            : notif
        )
      );

      // Let parent refresh or handle post-claim logic
      onNotificationsClaimed(notifId, result.bonus_ewi, result.bonus_ewe);
    } catch (err) {
      console.error('[NotificationIcon] claimReward exception =>', err);
    }
  };

  // 5) Clicking the icon => show the notifications “page”
  const handleClick = (e) => {
    e.preventDefault();
    setShowNotifications(true);
  };

  // 6) Count unclaimed => if 0, hide the icon
  const unclaimedCount = notifications.filter((n) => !n.claimed).length;

  return (
    <>
      {unclaimedCount > 0 && (
        <button className="notification-icon-button" onClick={handleClick}>
          <span className="notification-badge">{unclaimedCount}</span>
          <div className="notification-icon-emoji">
            <img src={not} alt="Notification Icon" className="notification-icon" />
          </div>
        </button>
      )}

      {showNotifications && (
        <NotificationsList
          notifications={notifications}
          onClose={() => setShowNotifications(false)}
          onClaim={handleClaimReward}
        />
      )}
    </>
  );
};

export default NotificationIcon;
