import React, { useState, useEffect, useRef, useCallback } from 'react';
import './AppleCatcher.css';
import UniverseData from '../../UniverseData';

const AppleCatcher = ({ onGameOver }) => {
  const mountedRef = useRef(false);

  const [gameState, setGameState] = useState({
    score: 0,
    time: 15,
    apples: [],
    playerPosition: 50,
    gameStarted: false,
    showResult: false
  });

  const [scoreAnimations, setScoreAnimations] = useState([]);

  // Store VFX setting in state, default true, load from localStorage
  const [vfxEnabled, setVfxEnabled] = useState(() => {
    const stored = localStorage.getItem('appleCatcherVfx');
    return stored === null ? true : stored === 'true';
  });

  const timerRef = useRef(null);
  const gameLoopRef = useRef(null);
  const lastUpdateRef = useRef(0);
  const gameEndedRef = useRef(false);

  // Toggle button to flip VFX on/off
  const toggleVfx = useCallback(() => {
    setVfxEnabled((prev) => {
      const newVal = !prev;
      localStorage.setItem('appleCatcherVfx', String(newVal));
      return newVal;
    });
  }, []);

  // Main game loop (delta time)
  const gameLoop = useCallback(
    (timestamp) => {
      if (!mountedRef.current) return;

      if (lastUpdateRef.current === 0) {
        lastUpdateRef.current = timestamp;
        gameLoopRef.current = requestAnimationFrame(gameLoop);
        return;
      }

      const delta = timestamp - lastUpdateRef.current;
      lastUpdateRef.current = timestamp;

      setGameState((prev) => {
        if (!prev.gameStarted) return prev;

        // Initialize local variables from state including time
        let { apples, score } = prev;
        let time = prev.time;

        // Apple fall speed
        const speedFactor = 0.03 * delta;

        // Move apples
        apples = apples.map((apple) =>
          apple.hasCollided ? apple : { ...apple, y: apple.y + speedFactor }
        );

        // Spawn chance, scaled by delta
        const spawnChance = 0.05 * (delta / 16.7);
        if (Math.random() < spawnChance && apples.length < 40) {
          apples.push({
            id: Date.now(),
            x: Math.random() * 100,
            y: 0,
            type: Math.random() > 0.5 ? 'good' : 'rotten',
            hasCollided: false
          });
        }

        // Collisions & off-screen
        const catcherWidth = 25;
        const leftEdge = prev.playerPosition - catcherWidth / 2;
        const rightEdge = prev.playerPosition + catcherWidth / 2;
        const catcherTop = 80;
        const nextApples = [];

        for (const apple of apples) {
          if (apple.hasCollided) {
            continue;
          }
          // Apple bottom
          const appleBottom = apple.y + 5;
          // Remove if off-screen
          if (appleBottom >= 100) {
            continue;
          }

          // Collision detection
          if (
            appleBottom >= catcherTop &&
            appleBottom < catcherTop + 5 &&
            apple.x >= leftEdge &&
            apple.x <= rightEdge
          ) {
            apple.hasCollided = true;
            const points = apple.type === 'good' ? 500 : -5000;
            score = Math.max(0, score + points);

            // Subtract 1 second from the timer if the apple is rotten
            if (apple.type === 'rotten') {
              time = Math.max(0, time - 1);
            }

            UniverseData.updateGameSession({
              type: apple.type,
              score: points,
              position: { x: apple.x, y: apple.y }
            });

            // Animate floating score
            const animId = `anim_${apple.id}_${Math.random()
              .toString(36)
              .substr(2, 9)}`;
            setScoreAnimations((prevAnims) => [
              ...prevAnims,
              { id: animId, x: apple.x, y: catcherTop - 15, score: points }
            ]);
            setTimeout(() => {
              setScoreAnimations((prevAnims) =>
                prevAnims.filter((anim) => anim.id !== animId)
              );
            }, 1000);
          } else {
            nextApples.push(apple);
          }
        }

        return { ...prev, apples: nextApples, score, time };
      });

      gameLoopRef.current = requestAnimationFrame(gameLoop);
    },
    [setScoreAnimations, gameLoopRef]
  );

  // Start game
  const startGame = useCallback(async () => {
    if (!mountedRef.current) return;

    // Check attempts from UniverseData
    const currentData = UniverseData.getCurrentData();
    if (!currentData?.bombs) {
      console.log('[AppleCatcher] No bombs data => cannot start');
      return;
    }
    let { attempts, last_activity } = currentData.bombs;
    attempts = attempts ?? 3;

    if (last_activity) {
      const now = Date.now();
      const lastTime = new Date(last_activity).getTime();
      const diffHours = (now - lastTime) / (1000 * 60 * 60);
      if (diffHours >= 24) {
        attempts = 3;
      }
    }

    if (attempts <= 0) {
      console.log('[AppleCatcher] No attempts left => cannot start');
      return;
    }

    try {
      const ok = UniverseData.handleBomb('start_game');
      if (!ok) {
        console.log('[AppleCatcher] handleBomb => not OK => no attempts?');
        return;
      }

      // Now we confirm the game truly started => set local minigame state
      localStorage.setItem('appleCatcherInProgress', 'true');

      await UniverseData.syncActions();

      setGameState((prev) => ({
        ...prev,
        gameStarted: true,
        time: 15,
        score: 0,
        apples: [],
        showResult: false
      }));

      gameEndedRef.current = false;
      lastUpdateRef.current = 0;
    } catch (error) {
      console.error('[AppleCatcher] Error starting game:', error);
    }
  }, []);

  // End game
  const endGame = useCallback(async () => {
    if (!mountedRef.current) return;
    if (gameEndedRef.current) return;
    gameEndedRef.current = true;

    setGameState((prev) => ({
      ...prev,
      gameStarted: false,
      showResult: true
    }));

    UniverseData.setGameSessionScore(gameState.score);
    UniverseData.handleBomb('end_game');
    await UniverseData.syncActions(null, true);

    clearInterval(timerRef.current);
    cancelAnimationFrame(gameLoopRef.current);

    console.log('[AppleCatcher] endGame => final local score:', gameState.score);
  }, [gameState.score]);

  // Timer logic
  useEffect(() => {
    if (!mountedRef.current) return;

    if (gameState.gameStarted && gameState.time > 0) {
      timerRef.current = setInterval(() => {
        setGameState((prev) => ({ ...prev, time: prev.time - 1 }));
      }, 1000);

      gameLoopRef.current = requestAnimationFrame(gameLoop);

      return () => {
        clearInterval(timerRef.current);
        cancelAnimationFrame(gameLoopRef.current);
      };
    } else if (gameState.gameStarted && gameState.time === 0) {
      endGame();
    }
  }, [gameState.gameStarted, gameState.time, gameLoop, endGame]);

  // Move catcher with touch
  const handleTouchMove = useCallback(
    (e) => {
      e.preventDefault();
      if (!mountedRef.current) return;
      if (!gameState.gameStarted) return;

      const touch = e.touches[0];
      const screenWidth = window.innerWidth;
      const catcherWidthPercent = (80 / screenWidth) * 100;
      const halfCatcher = catcherWidthPercent / 2;

      let newPos = (touch.clientX / screenWidth) * 100;
      newPos = Math.max(halfCatcher, Math.min(100 - halfCatcher, newPos));

      setGameState((prev) => ({ ...prev, playerPosition: newPos }));
    },
    [gameState.gameStarted]
  );

  // Close result modal
  const handleResultClose = () => {
    setGameState((prev) => ({ ...prev, showResult: false }));
    onGameOver?.(gameState.score);
    // Mark local minigame as ended
    localStorage.setItem('appleCatcherInProgress', 'false');
  };

  // On mount
  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
    }
  }, []);

  return (
    <div className="apple-catcher" onTouchMove={handleTouchMove}>
      <div className="game-info">
        <span>Score: {gameState.score}</span>
        <span>Time: {gameState.time}</span>
      </div>

      {/* Toggle VFX in top-right */}
      <button className="vfx-toggle-button" onClick={toggleVfx}>
        {vfxEnabled ? 'Disable VFX' : 'Enable VFX'}
      </button>

      {!gameState.gameStarted && !gameState.showResult && (
        <button className="start-button" onClick={startGame}>
          Start Game
        </button>
      )}

      <div className="game-area">
        {gameState.apples.map((apple) => {
          const appleClass = vfxEnabled
            ? `neon-apple ${apple.type === 'good' ? 'good-apple' : 'rotten-apple'}`
            : `plain-apple ${apple.type === 'good' ? 'good-apple' : 'rotten-apple'}`;

          return (
            <div
              key={apple.id}
              className={appleClass}
              style={{
                left: `${apple.x}%`,
                top: `${apple.y}%`,
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                position: 'absolute'
              }}
            />
          );
        })}

        {scoreAnimations.map((anim) => (
          <div
            key={anim.id}
            className={`score-animation ${anim.score > 0 ? 'positive' : 'negative'}`}
            style={{ left: `${anim.x}%`, top: `${anim.y}%` }}
            ref={(el) => {
              if (el) setTimeout(() => el.classList.add('active'), 50);
            }}
          >
            {anim.score > 0 ? `+${anim.score}` : anim.score}
          </div>
        ))}

        {/* Catcher */}
        <div
          className={vfxEnabled ? 'neon-catcher' : 'plain-catcher'}
          style={{
            left: `${gameState.playerPosition}%`,
            width: '80px',
            height: '30px',
            backgroundColor: vfxEnabled ? 'greenyellow' : 'grey',
            position: 'absolute',
            bottom: '20%',
            borderRadius: '13% 13% 100% 100%',
            transform: 'translateX(-50%)'
          }}
        />
      </div>

      {gameState.showResult && (
        <div className="result-modal">
          <div className="result-content">
            <h2>END GAME</h2>
            <p>Score: {gameState.score}</p>
            <button onClick={handleResultClose}>OK</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppleCatcher;
