// bg.js
import React, { useEffect, useRef } from 'react';
import '../css.components/bg.css';
import heart from '../../public/heart.png';

const Background = ({ vfxEnabled }) => {
  const sectionRef = useRef(null);

  const renderBlocks = () => {
    const section = sectionRef.current;
    if (!section) return;
    // Clear old if any
    section.innerHTML = '';

    for (let i = 0; i < 60; i++) {
      const span = document.createElement('span');
      span.classList.add('sp1');

      // If your code uses a "no-vfx" approach:
      if (vfxEnabled === false) {
        span.classList.add('no-vfx');
      }

      Object.assign(span.style, {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'none',
        width: 'calc(15vw)',
        height: 'calc(15vw)',
        zIndex: '2',
        transition: 'transform 0.1s ease',
      });

      const img = document.createElement('img');
      img.src = heart;
      img.style.width = '4vw';
      img.style.height = '4vw';
      img.style.position = 'absolute';
      img.style.zIndex = '3';

      span.appendChild(img);
      section.appendChild(span);
    }
  };

  useEffect(() => {
    renderBlocks();
  }, [vfxEnabled]);

  return <section className="bg1" ref={sectionRef}></section>;
};

export default Background;
