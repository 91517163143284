import React, { useState, useEffect } from 'react';
import '../css.components/DailyBonus.css';
import UniverseData from '../UniverseData';

const DEBUG = true;
function log(...args) {
  if (DEBUG) console.log('[DailyBonus Debug]', ...args);
}

// 7 days of rewards (purely for display - the server still decides final rewards)
const dailyRewards = [
  { day: 1, reward: '2500 EWI', collected: false },
  { day: 2, reward: '5000 EWI', collected: false },
  { day: 3, reward: '7500 EWI', collected: false },
  { day: 4, reward: '10000 EWI', collected: false },
  { day: 5, reward: '12500 EWI', collected: false },
  { day: 6, reward: '15000 EWI', collected: false },
  { day: 7, reward: '0.512 EWE',   collected: false },
];

/**
 * DailyBonus
 *
 * Props:
 *  - onDailyClaimFromParent(day, rewardString): callback for immediate local EWI/EWE display
 *
 * Reads local data from UniverseData => shows a 7-day cycle with streak logic:
 * 1) If the user has not claimed today => let them claim (activeDay).
 * 2) Once claimed, the day is “collected,” and we update UniverseData locally.
 * 3) A minimal daily_claim action is queued to the server in UniverseData.
 */
const DailyBonus = ({ onDailyClaimFromParent }) => {
  const [activeDay, setActiveDay] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState('00:00:00');
  const [rewards, setRewards] = useState(dailyRewards);

  // --------------------------------------------------------
  // 1) On mount => read streak_days, last_claim => figure out next day
  // --------------------------------------------------------
  useEffect(() => {
    const { streak_days = 0, last_claim = null } = UniverseData.getDailyRewardData() || {};
    log('On mount => streak_days=', streak_days, 'last_claim=', last_claim);

    let alreadyClaimedToday = false;
    if (last_claim) {
      const now = new Date();

      // Convert "now" to a date representing just the UTC day
      const nowUTC = new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
      );

      // Convert last_claim to a date representing that claim's UTC day
      const lastClaimDate = new Date(last_claim);
      const lastClaimUTC = new Date(
        Date.UTC(
          lastClaimDate.getUTCFullYear(),
          lastClaimDate.getUTCMonth(),
          lastClaimDate.getUTCDate()
        )
      );

      // If same day in UTC => user has claimed "today"
      if (nowUTC.getTime() === lastClaimUTC.getTime()) {
        alreadyClaimedToday = true;
      }
    }

    // Next day to claim in the 7-day cycle (1..7)
    const dayIndex = streak_days % 7; // 0..6
    const nextActiveDay = dayIndex + 1;

    // Mark days < nextActiveDay as collected
    const updatedRewards = dailyRewards.map((r) =>
      r.day < nextActiveDay ? { ...r, collected: true } : { ...r, collected: false }
    );
    setRewards(updatedRewards);

    if (alreadyClaimedToday) {
      // If user has already claimed => no more claims => activeDay=null
      setActiveDay(null);
      log('User already claimed => activeDay=null');
    } else {
      setActiveDay(nextActiveDay);
      log('User can claim => activeDay=', nextActiveDay);
    }
  }, []);

  // --------------------------------------------------------
  // 2) Countdown to next 00:00 UTC
  // --------------------------------------------------------
  useEffect(() => {
    const intervalId = setInterval(() => {
      const nowLocal = new Date();

      // Convert local time -> UTC timestamp
      const nowUtcTimestamp = Date.UTC(
        nowLocal.getUTCFullYear(),
        nowLocal.getUTCMonth(),
        nowLocal.getUTCDate(),
        nowLocal.getUTCHours(),
        nowLocal.getUTCMinutes(),
        nowLocal.getUTCSeconds()
      );

      // Next midnight in UTC
      let midnightUtcTimestamp = Date.UTC(
        nowLocal.getUTCFullYear(),
        nowLocal.getUTCMonth(),
        nowLocal.getUTCDate(),
        0, 0, 0
      );
      if (nowUtcTimestamp >= midnightUtcTimestamp) {
        midnightUtcTimestamp += 24 * 60 * 60 * 1000;
      }

      const timeUntilMidnight = midnightUtcTimestamp - nowUtcTimestamp;
      const hours = Math.floor(timeUntilMidnight / (1000 * 60 * 60));
      const minutes = Math.floor((timeUntilMidnight % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeUntilMidnight % (1000 * 60)) / 1000);

      const hh = hours.toString().padStart(2, '0');
      const mm = minutes.toString().padStart(2, '0');
      const ss = seconds.toString().padStart(2, '0');

      setTimeRemaining(`${hh}:${mm}:${ss}`);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  // --------------------------------------------------------
  // 3) User clicks a day => if it matches activeDay => claim
  // --------------------------------------------------------
  const handleDayClick = async (day) => {
    log('Clicked day=', day, 'activeDay=', activeDay);
    if (activeDay == null) {
      log('No active day => not claimable');
      return;
    }
    if (day !== activeDay) {
      log('Clicked day != activeDay => ignoring...');
      return;
    }

    // Already collected => skip
    const dayObj = rewards.find((r) => r.day === day);
    if (!dayObj || dayObj.collected) {
      log('Day already collected => ignoring');
      return;
    }

    // Attempt local daily claim => UniverseData
    // (We pass day + reward purely so local increments can match the UI,
    //  but the server sees only minimal data.)
    const success = UniverseData.handleDailyClaim(day, dayObj.reward);
    log('UniverseData.handleDailyClaim => success?', success);

    if (!success) {
      // Possibly user already claimed
      return;
    }

    // Mark day as collected
    const updatedRewards = rewards.map((r) =>
      r.day === day ? { ...r, collected: true } : r
    );
    setRewards(updatedRewards);

    // If parent wants immediate EWI/EWE update, do it:
    if (onDailyClaimFromParent) {
      onDailyClaimFromParent(day, dayObj.reward);
    }

    // Once claimed => can't claim again => activeDay=null
    setActiveDay(null);

    // Optionally do an immediate sync
    try {
      log('Syncing => UniverseData.syncActions()...');
      await UniverseData.syncActions();

      // After server sync, re-check local streak data
      const { streak_days = 0, last_claim = null } = UniverseData.getDailyRewardData() || {};
      const dayIndex = streak_days % 7;
      const nextActiveDay = dayIndex + 1;

      const reMarked = dailyRewards.map((r) =>
        r.day < nextActiveDay ? { ...r, collected: true } : { ...r, collected: false }
      );
      setRewards(reMarked);

      log('After sync => streak_days=', streak_days, 'last_claim=', last_claim);
    } catch (err) {
      console.error('[DailyBonus] syncActions error =>', err);
    }
  };

  // --------------------------------------------------------
  // 4) Render
  // --------------------------------------------------------
  return (
    <div className="calendar-container">
      <h2 className="calendar-header">
        Daily Rewards
      </h2>
        <span className="timer"> Next Day: {timeRemaining}</span>

      <div className="calendar-grid">
        {rewards.map((r) => {
          const isActive = activeDay === r.day;
          const isCollected = r.collected;

          return (
            <div
              key={r.day}
              className={`calendar-day ${isActive ? 'active' : ''} ${
                isCollected ? 'collected' : ''
              }`}
              onClick={() => handleDayClick(r.day)}
            >
              <div className="day-number">Day {r.day}</div>
              <div className="reward-info">
                <div className="reward-name">{r.reward}</div>
              </div>
              <div className="reward-action">
                {isActive && !isCollected && (
                  <button className="claim-button">Claim</button>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DailyBonus;
